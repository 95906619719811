import functions from "../functions";
import lang from "../property_lang";

const { getFormatedUUID } = functions;
const c_chart = [
  {
    id: getFormatedUUID(),
    label: lang["props.title"],
    type: "text",
    path: "props.content",
  },

  {
    id: getFormatedUUID(),
    label: "Select tables",
    type: "SelectNestedTables",
    path: "props.selectedTables",
  },
  // {
  //   id: getFormatedUUID(),
  //   label: lang["props.table.buttons.export"],
  //   type: "bool",
  //   path: "props.buttons.export.state",
  //   if_true: {
  //     value: true,
  //     label: lang["props.table.show"],
  //   },
  //   if_false: {
  //     value: false,
  //     label: lang["props.table.hide"],
  //   },
  // },
  {
    id: getFormatedUUID(),
    label: "WHERE",
    type: "JoiningTable",
    path: "props.joiningTable",
    path2: "props.joiningTables",
    tablespath: "props.joiningTable",
  },
  {
    id: getFormatedUUID(),
    label: "Chọn trường hiển thị",
    type: "SelectDisplayFields",
    path: "props.source.DisplayFields",
    pathTo: "props.to",
    fieldsTo: [
      {
        from: "page_id",
        to: "page_id",
      },
      {
        from: "page_title",
        to: "page_title",
      },
      {
        from: "params",
        to: "params",
      },
    ],
  },
  {
    id: getFormatedUUID(),
    label: lang["props.params"],
    type: "SelectCriteria",
    path: "props.params",
    tablespath: "props.tables",
  },
  // Nhu code sửa đường từ path: "props.CHART_TYPES" dẫn qua new_charts
  {
    id: getFormatedUUID(),
    label: "Biểu đồ",
    type: "SELECT_CHART_TYPES",
    // path: "props.CHART_TYPES",
    path: "props.new_charts",
  },
  {
    id: getFormatedUUID(),
    label: "Chọn trường lọc",
    type: "SelectFilteringFields",
    path: "props.filteringFields",
  },
  {
    id: getFormatedUUID(),
    label: lang["props.fields.calculate"],
    type: "tablecalculatefields",
    path: "props.source.calculates",
  },
  {
    id: getFormatedUUID(),
    label: lang["props.fomular"],
    type: "selection",
    path: "props.fomular",
    options: [
      {
        label: "SUM",
        value: "SUM",
      },

      {
        label: "AVERAGE",
        value: "AVERAGE",
      },

      {
        label: "COUNT",
        value: "COUNT",
      },
    ],
  },

  {
    id: getFormatedUUID(),
    label: lang["props.criterias"],
    type: "text",
    path: "props.criterias",
  },

  {
    id: getFormatedUUID(),
    label: lang["props.table.buttons.export"],
    type: "bool",
    path: "props.export.state",
    if_true: {
      value: true,
      label: lang["props.table.show"],
    },
    if_false: {
      value: false,
      label: lang["props.table.hide"],
    },
  },

  {
    id: getFormatedUUID(),
    label: lang["style.padding"],
    type: "text",
    path: "props.style.padding",
  },
  {
    id: getFormatedUUID(),
    label: lang["style.margin"],
    type: "text",
    path: "props.style.margin",
  },
];
export default c_chart;
