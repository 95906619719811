import { memo, useEffect, useState } from "react";
import Select from "react-select";
import functions from "../../../../../redux/configs/functions";
import ColorContext from "../../../../api/ContextTable";
import { Conditions } from "../../../../../Components/Conditions";
import { useSelector } from "react-redux";

function Component({ redirect_fields = [], mappedRedirect, onChange }) {
  const { getFormatedUUID } = functions;
  const [linkedFields, setLinkedFields] = useState(redirect_fields);
  const [color, setColor] = useState({});
  const { tables } = useSelector((s) => s);

  function handleChange({ type, payload }) {
    switch (type) {
      case "add":
        setLinkedFields((prev) => [
          { id: getFormatedUUID(), isPopup: false, page: {}, conditions: [] },
          ...prev,
        ]);
        break;

      case "update":
        setLinkedFields((prev) => {
          const newLinkedFields = [...prev];

          for (const index in newLinkedFields) {
            const field = newLinkedFields[index];
            if (field.id === payload.id) {
              for (const k in payload) {
                field[k] = payload[k];
              }
              break;
            }
          }

          return newLinkedFields;
        });
        break;
      case "delete":
        setLinkedFields((prev) =>
          prev.filter((item) => item.id !== payload.id)
        );
        break;
    }
  }

  useEffect(() => {
    onChange(linkedFields);
  }, [linkedFields]);

  return (
    <div
      className="p-2"
      style={{
        border: "1px #ccc solid",
        borderRadius: ".75em",
      }}
    >
      <button onClick={() => handleChange({ type: "add" })}>Add</button>
      <section>
        {Array.isArray(linkedFields) &&
          linkedFields?.map(({ id, page, isPopup, conditions }) => (
            <section key={id}>
              <button
                onClick={() =>
                  handleChange({ type: "delete", payload: { id } })
                }
              >
                Delete
              </button>
              <Select
                options={mappedRedirect}
                value={page}
                isClearable
                onChange={(option) => {
                  handleChange({
                    type: "update",
                    payload: { id, page: option },
                  });
                }}
              />
              <label className="mr-2">Hiển thị popup</label>
              <input
                type="checkbox"
                checked={isPopup}
                onChange={({ target: { checked } }) => {
                  handleChange({
                    type: "update",
                    payload: { id, isPopup: checked },
                  });
                }}
              />
              <ColorContext.Provider value={{ color, setColor }}>
                <Conditions
                  onChange={({ field_id, payload }) => {
                    handleChange({
                      type: "update",
                      payload: { id, conditions: payload },
                    });
                  }}
                  conditions={conditions}
                  field_id={""}
                  fields={tables}
                  tables={tables}
                />
              </ColorContext.Provider>
            </section>
          ))}
      </section>
    </div>
  );
}

export const LinkedFields = memo(Component);
