import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { memo } from "react";
import { ConvertStringToObject } from "../../../../../utils/ConvertStringToObject";
import { GroupByContainer } from "./GroupByContainer";
import { OrderByContainer } from "./OrderByContainer";
import { SelectContainer } from "./SelectContainer";

import { faTrash } from "@fortawesome/free-solid-svg-icons";
import functions from "../../../../../redux/configs/functions";
import { debounce } from "../../../../../utils/useDebounce";
import { ReactSortable } from "react-sortablejs";

const JoiningTable = ({
  joining = [],
  tables = [],
  onChange,
  handleDelete,
  onChangeOrder = () => {},
  lang,
}) => {
  const debounceOnchange = debounce((...args) => onChange(...args), 100);

  return (
    <div className="accordion" id="accordionJoining">
      <ReactSortable
        list={joining}
        setList={(list) => {
          onChangeOrder(list);
        }}
      >
        {joining.map(
          (
            {
              id,
              left_table,
              right_table,
              where_condition,
              group_by,
              order_by,
              select: prev,
              alias,
              custom_group_by,
              custom_group_by_outside_id,
              custom_sort = {},
              limit = {},
              unwind = {},
            },
            i
          ) => {
            const findTableName = tables.filter(
              (item) =>
                item.table_alias === left_table ||
                item.table_alias === right_table
            );
            return (
              <div className="p-1">
                <div
                  className="accordion-item accordion-item-chart"
                  key={id}
                  // style={{
                  //   display: "flex",
                  //   margin: "10px 0",
                  //   flexWrap: "wrap",
                  // }}
                >
                  <h2
                    className="accordion-header "
                    id={`panelsStayOpen-headingChart${id}`}
                    // onClick={() => onAccordionClick(i)}
                  >
                    <button
                      className="accordion-button accordion-button-left"
                      data-bs-toggle="collapse"
                      data-bs-target={`#panelsStayOpen-collapse${id}`}
                      aria-expanded={i === 0 ? "true" : "false"}
                      aria-controls={`panelsStayOpen-collapse${id}`}
                      type="button"
                    >
                      <span>
                        {findTableName
                          .map((item) => item.table_name)
                          .join(" - ")}
                      </span>
                      <div className="accordion-button-right">
                        <FontAwesomeIcon
                          icon={faTrash}
                          onClick={(e) => {
                            handleDelete(id);
                            e.stopPropagation();
                          }}
                        />
                      </div>
                    </button>
                  </h2>
                  <div
                    id={`panelsStayOpen-collapse${id}`}
                    className={`accordion-collapse collapse ${
                      i === 0 ? "show" : ""
                    }`}
                    aria-labelledby={`panelsStayOpen-headingChart${id}`}
                  >
                    <div className="accordion-body">
                      <section
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                        }}
                      >
                        <section>
                          <label>Unwind: </label>
                          <input
                            type="checkbox"
                            checked={unwind?.["left_table"]}
                            onChange={({ target: { checked } }) => {
                              const newUnwind = { ...unwind };
                              newUnwind["left_table"] = checked;

                              onChange(id, "unwind", newUnwind);
                            }}
                          />
                        </section>
                        <select
                          className="form-control"
                          onChange={({ target: { value } }) => {
                            onChange(id, "left_table", value);
                          }}
                        >
                          <option selected disabled></option>
                          {tables.map(({ id, table_alias, table_name }) => (
                            <option
                              key={id}
                              value={table_alias}
                              selected={left_table === table_alias}
                            >
                              {table_name}-{table_alias}-{id}
                            </option>
                          ))}
                        </select>
                        <div className="label-box">left join</div>

                        <select
                          className="form-control"
                          onChange={({ target: { value } }) => {
                            onChange(id, "right_table", value);
                          }}
                        >
                          <option selected disabled></option>
                          {tables.map(({ id, table_alias, table_name }) => (
                            <option
                              key={id}
                              value={table_alias}
                              selected={right_table === table_alias}
                            >
                              {table_name}-{table_alias}-{id}
                            </option>
                          ))}
                        </select>
                        <section>
                          <label>Unwind: </label>
                          <input
                            checked={unwind?.["right_table"]}
                            type="checkbox"
                            onChange={({ target: { checked } }) => {
                              const newUnwind = { ...unwind };
                              newUnwind["right_table"] = checked;

                              onChange(id, "unwind", newUnwind);
                            }}
                          />
                        </section>
                      </section>
                      <section>
                        <div className="label-box">Where</div>
                        <div className="where">
                          <div className="where-left mb-2">
                            <div className="label-box">{left_table}</div>
                            <textarea
                              className="form-control"
                              style={{ width: "100%" }}
                              value={where_condition?.["left_table"]}
                              onChange={({ target: { value } }) => {
                                const newData = { ...where_condition };
                                newData["left_table"] = value;
                                onChange(id, "where_condition", newData);
                              }}
                            ></textarea>
                          </div>
                          <div className="where-right">
                            <div className="label-box">{right_table}</div>
                            <textarea
                              className="form-control"
                              style={{ width: "100%" }}
                              value={where_condition?.["right_table"]}
                              onChange={({ target: { value } }) => {
                                const newData = { ...where_condition };
                                newData["right_table"] = value;
                                onChange(id, "where_condition", newData);
                              }}
                            ></textarea>
                          </div>
                        </div>
                      </section>
                      <GroupByContainer
                        groups={tables.find(
                          (table) => table.table_alias === left_table
                        )}
                        defaultValue={group_by["left_table"]}
                        onChange={(checked, fomular_alias) => {
                          const newData = { ...group_by };
                          const key = "left_table";
                          if (checked) {
                            if (newData[key]) {
                              newData[key].push(fomular_alias);
                            } else {
                              newData[key] = [fomular_alias];
                            }
                          } else {
                            newData[key] = newData[key].filter(
                              (item) => item !== fomular_alias
                            );
                          }
                          onChange(id, "group_by", newData);
                        }}
                      />
                      <div>CUSTOM GROUP BY</div>
                      <textarea
                        className="form-control"
                        style={{ width: "100%" }}
                        onChange={({ target: { value } }) => {
                          const newData = { ...custom_group_by };
                          newData["left_table"] = value;
                          onChange(id, "custom_group_by", newData);
                        }}
                      >
                        {custom_group_by?.["left_table"]}
                      </textarea>

                      <div>CUSTOM GROUP BY OUTSIDE ID</div>
                      <textarea
                        className="form-control"
                        style={{ width: "100%" }}
                        onChange={({ target: { value } }) => {
                          const newData = { ...custom_group_by_outside_id };
                          newData["left_table"] = value;
                          onChange(id, "custom_group_by_outside_id", newData);
                        }}
                      >
                        {custom_group_by_outside_id?.["left_table"]}
                      </textarea>

                      <section className="d-flex my-5 align-items-center">
                        <p className="my-0 mx-2">Limit: </p>
                        <input
                          type="text"
                          className="form-control"
                          value={limit?.["left_table"]}
                          onChange={({ target: { value } }) => {
                            const newLimit = { ...limit };

                            newLimit["left_table"] = value;

                            onChange(id, "limit", newLimit);
                          }}
                        />
                      </section>

                      <section>
                        <button
                          onClick={() => {
                            const newCustomSort = { ...custom_sort };
                            if (!newCustomSort["left_table"]) {
                              newCustomSort["left_table"] = [];
                            }
                            newCustomSort["left_table"].push({
                              id: functions.getFormatedUUID(),
                            });
                            onChange(id, "custom_sort", newCustomSort);
                          }}
                        >
                          Add sort
                        </button>
                        {custom_sort["left_table"]?.map(
                          ({ id: id_sort, value, position }) => (
                            <section key={id_sort} name="SORT NEED CSS">
                              <button
                                onClick={() => {
                                  const newCustomSort = { ...custom_sort };
                                  newCustomSort["left_table"] = newCustomSort[
                                    "left_table"
                                  ].filter(({ id }) => id !== id_sort);
                                  onChange(id, "custom_sort", newCustomSort);
                                }}
                              >
                                delete
                              </button>
                              <input
                                type="text"
                                value={position}
                                onChange={({ target: { value } }) => {
                                  const newCustomSort = { ...custom_sort };

                                  for (const index in newCustomSort[
                                    "left_table"
                                  ]) {
                                    const sort =
                                      newCustomSort["left_table"][index];
                                    if (sort.id === id_sort) {
                                      sort.position = value;
                                      break;
                                    }
                                  }

                                  onChange(id, "custom_sort", newCustomSort);
                                }}
                              />
                              <textarea
                                onChange={({ target: { value } }) => {
                                  const newCustomSort = { ...custom_sort };

                                  for (const index in newCustomSort[
                                    "left_table"
                                  ]) {
                                    const sort =
                                      newCustomSort["left_table"][index];
                                    if (sort.id === id_sort) {
                                      sort.value = value;
                                      break;
                                    }
                                  }

                                  onChange(id, "custom_sort", newCustomSort);
                                }}
                              >
                                {value}
                              </textarea>
                            </section>
                          )
                        )}
                      </section>
                      <OrderByContainer
                        groups={tables.find(
                          (table) => table.table_alias === left_table
                        )}
                        defaultValue={order_by?.["left_table"]}
                        onChange={(checked, fomular_alias, type) => {
                          const newData = { ...order_by };
                          const key = "left_table";
                          if (checked) {
                            if (!newData[key]) {
                              newData[key] = [];
                            }
                            const index_field = newData[key].findIndex(
                              ({ field }) => field === fomular_alias
                            );
                            if (index_field > -1) {
                              newData[key][index_field].type = type;
                            } else {
                              newData[key].push({ field: fomular_alias, type });
                            }
                          } else {
                            newData[key] = newData[key]?.filter(
                              (item) => item.field !== fomular_alias
                            );
                          }
                          onChange(id, "order_by", newData);
                        }}
                      />
                      <GroupByContainer
                        label="Order by"
                        groups={tables.find(
                          (table) => table.table_alias === left_table
                        )}
                        defaultValue={order_by?.["left_table"]}
                        onChange={(checked, fomular_alias) => {
                          const newData = { ...order_by };
                          const key = "left_table";
                          if (checked) {
                            if (newData[key]) {
                              newData[key].push(fomular_alias);
                            } else {
                              newData[key] = [fomular_alias];
                            }
                          } else {
                            newData[key] = newData[key].filter(
                              (item) => item !== fomular_alias
                            );
                          }
                          onChange(id, "order_by", newData);
                        }}
                      />
                      <GroupByContainer
                        groups={tables.find(
                          (table) => table.table_alias === right_table
                        )}
                        defaultValue={group_by["right_table"]}
                        onChange={(checked, fomular_alias) => {
                          const newData = { ...group_by };
                          const key = "right_table";
                          if (checked) {
                            if (newData[key]) {
                              newData[key].push(fomular_alias);
                            } else {
                              newData[key] = [fomular_alias];
                            }
                          } else {
                            newData[key] = newData[key].filter(
                              (item) => item !== fomular_alias
                            );
                          }
                          onChange(id, "group_by", newData);
                        }}
                      />
                      <GroupByContainer
                        label="Order by"
                        groups={tables.find(
                          (table) => table.table_alias === right_table
                        )}
                        defaultValue={order_by?.["right_table"]}
                        onChange={(checked, fomular_alias) => {
                          const newData = { ...order_by };
                          const key = "right_table";
                          if (checked) {
                            if (newData[key]) {
                              newData[key].push(fomular_alias);
                            } else {
                              newData[key] = [fomular_alias];
                            }
                          } else {
                            newData[key] = newData[key].filter(
                              (item) => item !== fomular_alias
                            );
                          }
                          onChange(id, "order_by", newData);
                        }}
                      />
                      <div>CUSTOM GROUP BY</div>
                      <textarea
                        className="form-control"
                        style={{ width: "100%" }}
                        onChange={({ target: { value } }) => {
                          const newData = { ...custom_group_by };
                          newData["right_table"] = value;
                          onChange(id, "custom_group_by", newData);
                        }}
                      >
                        {custom_group_by?.["right_table"]}
                      </textarea>

                      <div>CUSTOM GROUP BY OUTSIDE ID</div>
                      <textarea
                        className="form-control"
                        style={{ width: "100%" }}
                        onChange={({ target: { value } }) => {
                          const newData = { ...custom_group_by_outside_id };
                          newData["right_table"] = value;
                          onChange(id, "custom_group_by_outside_id", newData);
                        }}
                      >
                        {custom_group_by_outside_id?.["right_table"]}
                      </textarea>

                      <section className="d-flex my-5 align-items-center">
                        <p className="my-0 mx-2">Limit: </p>
                        <input
                          type="text"
                          className="form-control"
                          value={limit?.["right_table"]}
                          onChange={({ target: { value } }) => {
                            const newLimit = { ...limit };

                            newLimit["right_table"] = value;

                            onChange(id, "limit", newLimit);
                          }}
                        />
                      </section>

                      <label>Select</label>
                      <section>
                        <span>{left_table} alias: </span>
                        <input
                          type="text"
                          className="form-control"
                          value={alias?.["left_table"]}
                          onChange={({ target: { value } }) => {
                            const newData = { ...alias };
                            newData["left_table"] = value;

                            onChange(id, "alias", newData);
                          }}
                        />
                      </section>
                      <SelectContainer
                        label={left_table}
                        default_value={prev?.["left_table"]}
                        component_id={id}
                        onChange={(value) => {
                          const newData = { ...prev };
                          newData["left_table"] = ConvertStringToObject(value);

                          debounceOnchange(id, "select", newData);
                        }}
                      />
                      <section>
                        <span>{right_table} alias: </span>
                        <input
                          type="text"
                          className="form-control"
                          value={alias?.["right_table"]}
                          onChange={({ target: { value } }) => {
                            const newData = { ...alias };
                            newData["right_table"] = value;

                            debounceOnchange(id, "alias", newData);
                          }}
                        />
                      </section>
                      <SelectContainer
                        label={right_table}
                        default_value={prev?.["right_table"]}
                        component_id={id}
                        onChange={(value) => {
                          const newData = { ...prev };
                          newData["right_table"] = ConvertStringToObject(value);

                          debounceOnchange(id, "select", newData);
                        }}
                      />
                    </div>
                  </div>
                  {/* <button
                  className="btn btn-danger"
                  onClick={() => {
                    handleDelete(id);
                  }}
                >
                  XOA
                </button> */}
                </div>
              </div>
            );
          }
        )}
      </ReactSortable>
    </div>
  );
};
export const JoiningTableContainer = memo(JoiningTable);
