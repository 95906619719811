import Select from "react-select";

export const ItemTypeModal = ({ cb = () => {} }) => {
  const options = [
    { label: "Logical Operation", value: "logical" },
    { label: "Comparison Operators", value: "comparison" },
  ];
  return (
    <Select
      options={options}
      onChange={({ value }) => {
        cb(value);
      }}
    />
  );
};
