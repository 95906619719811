import {
  faAngleRight,
  faAngleUp,
  faTrash,
  faCirclePlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import functions from "../../../../../redux/configs/functions";
import { ConvertStringToObject } from "../../../../../utils/ConvertStringToObject";
import { JoiningTableContainer } from "./JoiningTableContainer";
import { SelectContainer } from "./SelectContainer";
import Select from "react-select";
import { debounce } from "../../../../../utils/useDebounce";

function JoiningTablesComponent({
  handleUpdate,
  handleUpdateJoiningField,
  DATA = [],
  selectedCpn,
  tables: initialTables,
  joining_field,
}) {
  //accrodion
  const [showData, setShowData] = useState(false);
  const [showJoining, setShowJoining] = useState({});
  const [showSelectroot, setShowSelectroot] = useState({});

  // show bang data
  const handeleShowData = () => {
    setShowData(!showData);
  };

  // show bang joining
  const handeleShowJoining = (id) => {
    setShowJoining((prev) => {
      if (prev[id] === undefined) {
        prev[id] = true;
      } else {
        prev[id] = !prev[id];
      }

      return { ...prev };
    });
  };

  // show bang joining
  const handeleShowSelectroot = (id) => {
    setShowSelectroot((prev) => {
      if (prev[id] === undefined) {
        prev[id] = true;
      } else {
        prev[id] = !prev[id];
      }

      return { ...prev };
    });
  };

  const { getFormatedUUID } = functions;
  const { tables: _tables, lang } = useSelector((s) => s);
  const tables = initialTables || _tables;

  const initial_item = {
    id: getFormatedUUID(),
    joining_type: "left_join",
    left_table: "",
    right_table: "",
    where_condition: {},
    group_by: {},
    custom_group_by: {},
    custom_group_by_outside_id: {},
    order_by: {},
    select: {},
    alias: {},
  };

  const [joiningTable, setJoiningTable] = useState(DATA);

  useEffect(() => {
    if (DATA.length) {
      setJoiningTable(DATA);
    }
  }, [DATA]);

  const [componentId, setComponentId] = useState(selectedCpn.id);

  useEffect(() => {
    setComponentId(selectedCpn.id);
  }, [selectedCpn]);

  useEffect(() => {
    handleUpdate(joiningTable);
  }, [joiningTable]);

  const handleChange = (id, type, value, joining) => {
    for (const k in joining) {
      const item = joining[k];
      if (item.id === id) {
        if (!joining[k][type]) {
          joining[k][type] = initial_item[type];
        }
        joining[k][type] = value;
        break;
      }
    }
    return joining;
  };

  const handleDelete = (id, joining) => {
    return joining.filter(({ id: _id }) => _id !== id);
  };

  const handleAddOutlet = (joining = []) => {
    joining.push(initial_item);
    return joining;
  };

  const handleQueries = ({ type, payload }) => {
    switch (type) {
      case "add":
        setJoiningTable((prev) => [
          { id: functions.getFormatedUUID(), tables: [], select_root: {} },
          ...prev,
        ]);
        break;
      case "delete":
        setJoiningTable((prev) => prev.filter(({ id }) => id !== payload.id));
        break;
      case "update":
        setJoiningTable((prev) => {
          const newJoiningTable = [...prev];
          for (const index in newJoiningTable) {
            const { id } = newJoiningTable[index];
            if (id === payload.id) {
              for (const k in payload) {
                newJoiningTable[index][k] = payload[k];
              }
            }
          }
          return newJoiningTable;
        });
        break;
    }
  };
  const debounceOnchange = debounce((args) =>
    handleQueries({
      type: "update",
      payload: args,
    })
  );
  return (
    <div className="padding-1rem">
      <div className="c-chart p-0">
        <section className=" d-flex">
          <div
            className={` ${
              showData ? "chart-header pb-2" : "chart-header-of"
            }  w-100`}
            onClick={handeleShowData}
          >
            <div className="chart-label">
              <FontAwesomeIcon
                icon={showData ? faAngleUp : faAngleRight}
                className="me-2"
              />

              {lang["Data Joining"]}
            </div>
          </div>
          <div className="incon-plus-joining">
            <FontAwesomeIcon
              icon={faCirclePlus}
              onClick={() => handleQueries({ type: "add" })}
            />
          </div>
        </section>
        {showData &&
          joiningTable.map(
            ({ id, tables: joining, select_root, query_type, alias }) => {
              return (
                <section key={id}>
                  <section className="d-flex">
                    <div className="col-2 trash-values">
                      <FontAwesomeIcon
                        icon={faTrash}
                        onClick={() =>
                          handleQueries({ type: "delete", payload: { id } })
                        }
                      />
                    </div>
                    <Select
                      value={query_type}
                      onChange={(option) => {
                        handleQueries({
                          type: "update",
                          payload: { id, query_type: option },
                        });
                      }}
                      options={[
                        { label: "Primary", value: "p" },
                        { label: "Secondary", value: "s" },
                      ]}
                    />
                  </section>
                  <section>
                    <label>Alias: </label>
                    <input
                      type="text"
                      value={alias}
                      onChange={({ target: { value } }) => {
                        handleQueries({
                          type: "update",
                          payload: { id, alias: value },
                        });
                      }}
                    />
                  </section>
                  <div className="p-1">
                    <div className="joining-container p-0">
                      <div className="joining-header  d-flex flex-warp">
                        <div
                          className="chart-label m-2  me-auto"
                          onClick={() => {
                            handeleShowJoining(id);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={showJoining?.[id] ? faAngleUp : faAngleRight}
                            className="me-2"
                          />
                          {lang["Joining"]}
                        </div>
                        <div className="incon-plus-joining">
                          <FontAwesomeIcon
                            icon={faCirclePlus}
                            onClick={() => {
                              handleQueries({
                                type: "update",
                                payload: {
                                  id,
                                  tables: handleAddOutlet(joining),
                                },
                              });
                            }}
                          />
                        </div>
                      </div>
                      {showJoining?.[id] && (
                        <section>
                          <JoiningTableContainer
                            joining={joining}
                            tables={tables}
                            lang={lang}
                            onChange={(...props) => {
                              handleQueries({
                                type: "update",
                                payload: {
                                  id,
                                  tables: handleChange(...props, joining),
                                },
                              });
                            }}
                            handleDelete={(_id) => {
                              handleQueries({
                                type: "update",
                                payload: {
                                  id,
                                  tables: handleDelete(_id, joining),
                                },
                              });
                            }}
                          />
                        </section>
                      )}
                    </div>
                    <div className="joining-container p-0">
                      <div
                        className="joining-header mt-1 p-1"
                        onClick={() => {
                          handeleShowSelectroot(id);
                        }}
                      >
                        <div className="chart-label m-2 ">
                          <FontAwesomeIcon
                            icon={
                              showSelectroot?.[id] ? faAngleUp : faAngleRight
                            }
                            className="me-2"
                          />
                          {lang["Statement"]}
                        </div>
                      </div>
                      {showSelectroot?.[id] && (
                        <div className="p-1">
                          <SelectContainer
                            name={selectedCpn?.name}
                            label={"Select Root"}
                            default_value={select_root}
                            component_id={componentId}
                            onChange={(value) => {
                              const obj = ConvertStringToObject(value);
                              debounceOnchange({
                                id,
                                select_root: obj,
                              });
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </section>
              );
            }
          )}
      </div>
    </div>
  );
}
export const JoiningTablesItem = memo(JoiningTablesComponent);
