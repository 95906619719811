import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faIcons,
  faRocket,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import lang from "../../../redux/configs/property_lang";

export default () => {
  const [icons, setIcons] = useState([]);
  const iconsObject = useSelector((state) => state.icons);

  const page = useSelector((state) => state.cache.page);
  const dispatch = useDispatch();
  const [isIcon, setIsIcon] = useState();

  useEffect(() => {
    setIcons(Object.values(iconsObject));
  }, []);

  const setIcon = (icon) => {
    setIsIcon(icon);
    dispatch({
      branch: "design-ui",
      type: "pageChangeIcon",
      payload: {
        icon,
      },
    });
  };
  const handleColorChange = (e) => {
    if (isIcon) {
      setIcon({ ...isIcon, color: e.target.value });
    }
  };
  const handleSizeChange = (e) => {
    if (isIcon) {
      setIcon({ ...isIcon, fontSize: parseInt(e.target.value) });
    }
  };
  return (
    <div className="modal-change-icon">
      <div className="floating-box change-icon">
        <div className="title">
          <span>Chọn icon mới</span>
        </div>

        <div className="icons-container">
          <div className="icons">
            {icons.map((icon) => {
              return (
                <div
                  data-toggle="modal"
                  data-target="#modalIcon"
                  className="icon"
                  key={icon.id}
                  onClick={() => {
                    setIcon(icon);
                  }}
                >
                  <FontAwesomeIcon icon={icon.icon} />
                  <span className="icon-name">{icon.lang.vi}</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="modalIcon"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-backdrop="false"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Set font size and color for icon
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className={`color-box d-flex`}>
                <label>{lang["style.icon"]}:</label>
                <input
                  type="color"
                  className="color-input ml-3"
                  onChange={handleColorChange}
                  // value={page.color}
                />
              </div>
              <div className="property d-flex mt-3 align-items-center">
                <div className="label-box">
                  <span>{lang["styleIcon.fontsize"]}:</span>
                </div>
                <div className="input-box ml-3">
                  <input
                    type="number"
                    className="form-control"
                    onChange={handleSizeChange}
                    // value={page.fontSize}
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              {/* <button type="button" class="btn btn-primary">
                Save changes
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
