import functions from "../../functions";

const { getFormatedUUID } = functions;
const conditionComponent = [
  {
    id: getFormatedUUID(),
    label: "Conditions",
    type: "Conditions",
    path: "props.conditions",
    path3: "props.apis",
  },
];
export { conditionComponent };
