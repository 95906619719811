import { memo, useEffect, useMemo, useState } from "react";
// import CreatableSelect from "react-select/creatable";
import {
  faAngleRight,
  faAngleUp,
  faBold,
  faPlusCircle,
  faTrash,
  faUnderline,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import Select from "react-select";
import { ReactSortable } from "react-sortablejs";
import functions from "../../../../../redux/configs/functions";
import { SearchContainer } from "./SearchContainer";
import { LinkedFields } from "./LinkedFields";

function Component(props) {
  const {
    selectedCpn: {
      props: { source, joiningTable = {}, joiningTables = [] } = {},
    },
    path,
    getPropByPath,
    updateSelectedComponent,
    selectedCpn,
    pathPopup,
  } = props;

  const { pages } = useSelector((state) => state);
  const { select_root = {}, tables } = joiningTable;
  const { tables: root_tables, lang } = useSelector((s) => s);
  const [showSelectDisplay, setSelectDisplay] = useState(false);
  const [isTextDecoration, setTextDecoration] = useState(false);
  const [isFontWeight, setFontWeight] = useState(false);

  const splittedPathPopup = pathPopup ? pathPopup.split(".") : [];

  const { getFormatedUUID } = functions;
  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, zIndex: 9999 }),
  };

  const FIELDS = useMemo(() => {
    const _tables = [];
    const _fields = [];

    for (const index in tables) {
      const table = tables[index];
      if (table.left_table && !_tables.includes(table.left_table)) {
        _tables.push(table.left_table);
        const corresponding_table = root_tables.find(
          ({ table_alias }) => table_alias === table.left_table
        );
        if (corresponding_table) {
          _fields.push(...corresponding_table.fields);
        }
      }

      if (table.right_table && !_tables.includes(table.right_table)) {
        _tables.push(table.right_table);
        const corresponding_table = root_tables.find(
          ({ table_alias }) => table_alias === table.right_table
        );
        if (corresponding_table) {
          _fields.push(...corresponding_table.fields);
        }
      }
    }

    return _fields;
  }, [tables]);

  const { tables: ROOT_TABLES } = useSelector((s) => s);
  const MAPPED_FIELDS = useMemo(() => {
    const FIELDS = [];
    for (const index in ROOT_TABLES) {
      const { fields } = ROOT_TABLES[index];
      FIELDS.push(...fields);
    }
    return FIELDS;
  }, [ROOT_TABLES]);

  useEffect(() => {
    const fields = [];
    for (const k in select_root) {
      let alias = "";
      if (typeof select_root[k] === "string") {
        alias = select_root[k]?.split("-$")?.at(-1)?.split(".")?.at(-1);
      }
      alias = alias[0] === "$" ? alias.slice(1) : alias;
      const field = FIELDS.find(({ fomular_alias }) => fomular_alias === alias);
      if (field) {
        fields.push(field);
      }
    }
    if (fields.length) {
      updateSelectedComponent(fields, ["props", "FIELDS"]);
    }
  }, [select_root, FIELDS]);

  useEffect(() => {
    const fields = [];
    for (const index in joiningTables) {
      const { select_root } = joiningTables[index];
      for (const k in select_root) {
        let alias = "";
        if (typeof select_root[k] === "string") {
          alias = select_root[k]?.split("-$")?.at(-1)?.split(".")?.at(-1);
        }
        alias = alias[0] === "$" ? alias.slice(1) : alias;
        const field = FIELDS.find(
          ({ fomular_alias }) => fomular_alias === alias
        );
        if (field) {
          fields.push(field);
        }
      }
    }
    if (fields.length) {
      updateSelectedComponent(fields, ["props", "FIELDS"]);
    }
  }, [joiningTables, FIELDS]);

  const options = [];

  for (const i in source?.calculates) {
    options.push({
      label: source.calculates[i].display_name,
      value: source.calculates[i].display_name,
      formula: source.calculates[i].fomular,
    });
  }

  for (const index in joiningTables) {
    const { select_root, alias } = joiningTables[index];
    for (const k in select_root) {
      options.push({
        label: `${k}-${select_root[k]}`,
        value: k,
        alias,
      });
    }
  }

  for (const k in select_root) {
    options.push({
      label: `${k}-${select_root[k]}`,
      value: k,
    });
  }

  const [fields, setFields] = useState(source?.DisplayFields || []);

  const handleShowSelectDisplay = () => {
    setSelectDisplay(!showSelectDisplay);
  };

  function AddAnOutlet() {
    setFields((prev) => [
      ...prev,
      {
        id: getFormatedUUID(),
        label: "",
        value: "",
      },
    ]);
  }

  function handleChangeOption(payload) {
    const { id, value, label, color, fontSize, type } = payload;

    setFields((prev) => {
      const newFields = [...prev];
      for (const index in newFields) {
        if (newFields[index].id === id) {
          for (const k in payload) {
            newFields[index][k] = payload[k];
          }
          break;
        }
      }
      return newFields;
    });
  }

  function handleRemoveOption(id) {
    setFields((prev) => prev.filter((field) => field.id !== id));
  }

  function handleRemoveOption(id) {
    setFields((prev) => prev.filter((field) => field.id !== id));
    let prevFieldsAndCalculates = selectedCpn?.props?.source?.display_fields;

    // Convert the lookup object back to an array
    const newArray = prevFieldsAndCalculates.filter((field) => field.id !== id);

    // let newFieldsAndCalculates = prevFieldsAndCalculates.concat(fields);
    updateSelectedComponent(newArray, ["props", "source", "display_fields"]);
  }

  function handleUpdateOrderFields(fields) {
    setFields(fields);
  }

  useEffect(() => {
    updateSelectedComponent(fields, path.split("."));

    // thinh this is where i need to put the value in display fields
    let prevFieldsAndCalculates = selectedCpn?.props?.source?.display_fields;
    // console.log("this is prevFieldsAndCalculates", prevFieldsAndCalculates);
    // Create a lookup object from arrayTwo
    const lookup = {};
    prevFieldsAndCalculates?.forEach((item) => {
      lookup[item.id] = item;
    });

    // Iterate through arrayOne and add/replace items in the lookup object
    fields?.forEach((item) => {
      lookup[item.id] = item;
    });

    // Convert the lookup object back to an array
    const mergedArray = Object.values(lookup);
    // let newFieldsAndCalculates = prevFieldsAndCalculates.concat(fields);
    updateSelectedComponent(mergedArray, ["props", "source", "display_fields"]);
  }, [fields]);

  useEffect(() => {
    setFields(source?.DisplayFields || []);
  }, [props?.selectedCpn?.id]);

  const mappedRedirect = [];

  pages.map((item) => {
    mappedRedirect.push({
      ...item,
      value: item.page_title,
      label: item.page_title,
    });
  });

  return (
    <div className="padding-1rem">
      <div className="c-chart p-0">
        <div
          className={` ${
            showSelectDisplay ? "chart-header" : "chart-header-of p-0"
          } d-flex flex-warp `}
        >
          <div className="chart-label m-2 " onClick={handleShowSelectDisplay}>
            <FontAwesomeIcon
              icon={showSelectDisplay ? faAngleUp : faAngleRight}
              className="me-2"
            />

            {lang["Display field"]}
          </div>
          <div className="incon-plus-chart ms-auto">
            <div className="add-icon" onClick={() => AddAnOutlet()}>
              <FontAwesomeIcon icon={faPlusCircle} />
            </div>
          </div>
        </div>
        {showSelectDisplay && (
          <div className="accordion" id="accordionExample">
            <section>
              <ReactSortable
                list={fields}
                setList={(list) => {
                  handleUpdateOrderFields(list);
                }}
              >
                {fields.map(
                  (
                    {
                      id,
                      value,
                      label,
                      exported_fields,
                      search_fields,
                      redirect_fields,
                      color,
                      fontSize,
                      textDecoration,
                      fontWeight,
                    },
                    i
                  ) => (
                    <section key={id} className="p-1">
                      <div className="accordion-item accordion-item-chart ">
                        <h2
                          className="accordion-header"
                          id={`panelsStayOpen-headingChart${id}`}
                        >
                          <button
                            className="accordion-button accordion-button-left"
                            data-bs-toggle="collapse"
                            data-bs-target={`#panelsStayOpen-collapse${id}`}
                            aria-expanded={i === 0 ? "true" : "false"}
                            aria-controls={`panelsStayOpen-collapse${id}`}
                            type="button"
                          >
                            <span className="ml-2">
                              {label || `Trường hiển thị ${i + 1}`}{" "}
                            </span>
                            <div className="accordion-button-right">
                              <FontAwesomeIcon
                                icon={faTrash}
                                onClick={() => handleRemoveOption(id)}
                              />
                            </div>
                          </button>
                        </h2>

                        <div
                          id={`panelsStayOpen-collapse${id}`}
                          className={`accordion-collapse collapse ${
                            i === 0 ? "show" : ""
                          }`}
                          aria-labelledby={`panelsStayOpen-headingChart${id}`}
                        >
                          <div className="accordion-body p-0">
                            <div className="m-2">
                              <div className="d-flex align-items-center m-2 property">
                                <label>Tên trường</label>
                                <div className="input-box ml-2">
                                  <input
                                    type="text"
                                    onChange={({ target: { value } }) => {
                                      handleChangeOption({ id, label: value });
                                    }}
                                    value={label}
                                  />
                                </div>
                              </div>

                              <Select
                                styles={customStyles}
                                isSearchable
                                isMulti
                                options={options}
                                value={value}
                                onChange={(option) => {
                                  const mapped_options = [];
                                  for (const index in option) {
                                    const field = source?.fields?.find(
                                      (f) =>
                                        f.fomular_alias === option[index]?.value
                                    );
                                    mapped_options.push({
                                      ...option[index],
                                      field,
                                    });
                                  }
                                  handleChangeOption({
                                    id,
                                    value: mapped_options,
                                  });
                                }}
                              />
                            </div>
                            <div className="m-2" style={{ zIndex: "1" }}>
                              <label>Trường export</label>
                              <Select
                                styles={customStyles}
                                isMulti
                                options={options}
                                value={exported_fields}
                                onChange={(option) => {
                                  handleChangeOption({
                                    id,
                                    exported_fields: option,
                                  });
                                }}
                              />
                            </div>
                            <div className="m-2">
                              <label>Trường Link</label>
                              <LinkedFields
                                mappedRedirect={mappedRedirect}
                                redirect_fields={redirect_fields}
                                onChange={(payload) => {
                                  handleChangeOption({
                                    id,
                                    redirect_fields: payload,
                                  });
                                }}
                              />
                            </div>
                            <div className="m-2">
                              <label>Trường tìm kiếm</label>
                              <SearchContainer
                                options={value}
                                FIELDS={FIELDS}
                                MAPPED_FIELDS={MAPPED_FIELDS}
                                calculates={options}
                                default_value={search_fields}
                                onChange={(fields) => {
                                  handleChangeOption({
                                    id,
                                    search_fields: fields,
                                  });
                                }}
                              />
                            </div>
                            <div className="m-2">
                              <label>Trường màu sắc và cỡ chữ</label>
                              <div
                                style={{
                                  border: "1px #ccc solid",
                                  borderRadius: ".75em",
                                }}
                              >
                                <div className="p-2">
                                  <div className="property m-0 mb-2">
                                    <div
                                      className="label-box"
                                      style={{ width: " 35%" }}
                                    >
                                      <span>Màu chữ</span>
                                    </div>
                                    <div className="color-box">
                                      <input
                                        type="color"
                                        className="color-input"
                                        value={color}
                                        onChange={(e) => {
                                          handleChangeOption({
                                            id,
                                            color: e.target.value,
                                            type: "Color",
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="property m-0 mb-2">
                                    <div
                                      className="label-box"
                                      style={{ width: " 35%" }}
                                    >
                                      <span>Cỡ chữ</span>
                                    </div>
                                    <div className="input-box">
                                      <input
                                        type="number"
                                        value={fontSize}
                                        placeholder="px"
                                        onChange={(e) => {
                                          handleChangeOption({
                                            id,
                                            fontSize: parseInt(e.target.value),
                                            type: "fontSize",
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="property m-0 mb-2">
                                    <div
                                      className="label-box"
                                      style={{ width: " 35%" }}
                                    >
                                      <span>Gạch chân</span>
                                    </div>
                                    <div
                                      className={`iconic-switch ${
                                        textDecoration ? "switch-activated" : ""
                                      }`}
                                      onClick={(e) => {
                                        setTextDecoration((prev) => !prev);
                                        handleChangeOption({
                                          id,
                                          textDecoration: isTextDecoration,
                                        });
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faUnderline} />
                                    </div>
                                  </div>
                                  <div className="property m-0 mb-2">
                                    <div
                                      className="label-box"
                                      style={{ width: " 35%" }}
                                    >
                                      <span>In đậm</span>
                                    </div>
                                    <div
                                      className={`iconic-switch ${
                                        fontWeight ? "switch-activated" : ""
                                      }`}
                                      onClick={(e) => {
                                        setFontWeight((prev) => !prev);
                                        handleChangeOption({
                                          id,
                                          fontWeight: isFontWeight,
                                        });
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faBold} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  )
                )}
              </ReactSortable>
            </section>
          </div>
        )}
      </div>
    </div>
  );
}
export const SelectDisplayFields = memo(Component, (oldProps, newProps) => {
  return oldProps?.selectedCpn?.id === newProps?.selectedCpn?.id;
});
