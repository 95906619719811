import functions from "../functions";
import lang from "../property_lang";
import {
  faAlignCenter,
  faAlignLeft,
  faAlignRight,
  faAlignJustify,
  faItalic,
  faBold,
  faUnderline,
} from "@fortawesome/free-solid-svg-icons";
const { getFormatedUUID } = functions;

const button_link = [
  {
    id: getFormatedUUID(),
    label: lang["props.title"],
    type: "text",
    path: "props.title",
  },

  {
    id: getFormatedUUID(),
    label: lang["props.page"],
    type: "selectPage",
    path: "props.to",
    fields: [
      {
        from: "page_id",
        to: "page_id",
      },
      {
        from: "page_title",
        to: "page_title",
      },
      {
        from: "params",
        to: "params",
      },
    ],
    display_value: "page_title",
  },

  {
    id: getFormatedUUID(),
    label: lang["props.params"],
    type: "showParams",
    path: "props.to.params",
  },

  {
    id: getFormatedUUID(),
    label: lang["props.icon"],
    type: "icon",
    path: "props.icon",
  },

  {
    id: getFormatedUUID(),
    label: lang["props.popup"],
    type: "showPopup",
    path: "props.isPopup",
  },

  {
    id: getFormatedUUID(),
    label: lang["props.prefix"],
    type: "text",
    path: "props.prefix",
  },

  {
    id: getFormatedUUID(),
    label: lang["props.postfix"],
    type: "text",
    path: "props.postfix",
  },

  {
    id: getFormatedUUID(),
    label: lang["styleIcon.fontsize"],
    type: "number",
    path: "props.style.fontSize",
  },

  {
    id: getFormatedUUID(),
    label: lang["stylePrefix.fontsize"],
    type: "number",
    path: "props.stylePrefix.fontSize",
  },

  {
    id: getFormatedUUID(),
    label: lang["stylePostfix.fontsize"],
    type: "number",
    path: "props.stylePostfix.fontSize",
  },

  {
    id: getFormatedUUID(),
    label: lang["style.icon"],
    type: "color",
    path: "props.style.color",
  },

  {
    id: getFormatedUUID(),
    label: lang["style.prefix"],
    type: "color",
    path: "props.stylePrefix.color",
  },

  {
    id: getFormatedUUID(),
    label: lang["style.postfix"],
    type: "color",
    path: "props.stylePostfix.color",
  },

  {
    id: getFormatedUUID(),
    label: lang["style.background"],
    type: "color",
    path: "props.style.backgroundColor",
  },

  {
    id: getFormatedUUID(),
    label: lang["style.alignment"],
    type: "iconicSwitchingGroup",
    path: "props.style.textAlign",
    defaultValue: "left",
    buttons: [
      {
        id: getFormatedUUID(),
        icon: faAlignLeft,
        value: "left",
      },
      {
        id: getFormatedUUID(),
        icon: faAlignCenter,
        value: "center",
      },
      {
        id: getFormatedUUID(),
        icon: faAlignRight,
        value: "right",
      },
      {
        id: getFormatedUUID(),
        icon: faAlignJustify,
        value: "justify",
      },
    ],
  },

  {
    id: getFormatedUUID(),
    label: lang["style.justifycontents"],
    type: "iconicSwitchingGroup",
    path: "props.style.justifyContent",
    defaultValue: "left",
    buttons: [
      {
        id: getFormatedUUID(),
        icon: faAlignLeft,
        value: "flex-start",
      },
      {
        id: getFormatedUUID(),
        icon: faAlignCenter,
        value: "center",
      },
      {
        id: getFormatedUUID(),
        icon: faAlignRight,
        value: "flex-end",
      },
    ],
  },

  {
    id: getFormatedUUID(),
    label: lang["style.italic"],
    type: "iconicSwitching",
    path: "props.style.fontStyle",
    values: ["unset", "italic"],
    icon: faItalic,
  },

  {
    id: getFormatedUUID(),
    label: lang["style.bold"],
    type: "iconicSwitching",
    path: "props.style.fontWeight",
    values: ["unset", "bold"],
    icon: faBold,
  },

  {
    id: getFormatedUUID(),
    label: lang["stylePrefix.bold"],
    type: "iconicSwitching",
    path: "props.stylePrefix.fontWeight",
    values: ["unset", "bold"],
    icon: faBold,
  },

  {
    id: getFormatedUUID(),
    label: lang["stylePostfix.bold"],
    type: "iconicSwitching",
    path: "props.stylePostfix.fontWeight",
    values: ["unset", "bold"],
    icon: faBold,
  },

  {
    id: getFormatedUUID(),
    label: lang["style.underline"],
    type: "iconicSwitching",
    path: "props.style.textDecoration",
    values: ["unset", "underline"],
    icon: faUnderline,
  },

  {
    id: getFormatedUUID(),
    label: lang["styleIcon.margin"],
    type: "text",
    path: "props.style.margin",
  },

  {
    id: getFormatedUUID(),
    label: lang["styleIcon.padding"],
    type: "text",
    path: "props.style.padding",
  },

  {
    id: getFormatedUUID(),
    label: lang["stylePrefix.margin"],
    type: "text",
    path: "props.stylePrefix.margin",
  },

  {
    id: getFormatedUUID(),
    label: lang["stylePrefix.padding"],
    type: "text",
    path: "props.stylePrefix.padding",
  },

  {
    id: getFormatedUUID(),
    label: lang["stylePostfix.margin"],
    type: "text",
    path: "props.stylePostfix.margin",
  },

  {
    id: getFormatedUUID(),
    label: lang["stylePostfix.padding"],
    type: "text",
    path: "props.stylePostfix.padding",
  },
];
export default button_link;
