import { memo, useEffect, useState } from "react";
import functions from "../../../../../../redux/configs/functions";
import { JoiningTableItem } from "../JoiningTable";

function Component(props) {
  const {
    path,
    selectedCpn,
    updateSelectedComponent,
    getPropByPath,
    tables: initialTables,
  } = props;
  const INITIAL_DATA = getPropByPath(path.split("."), selectedCpn);
  const [tables, setTables] = useState(INITIAL_DATA || []);

  useEffect(() => {
    if (INITIAL_DATA.length) {
      setTables(INITIAL_DATA);
    }
  }, [INITIAL_DATA]);

  const { getFormatedUUID } = functions;

  function handleChange({ type, payload }) {
    switch (type) {
      case "add":
        setTables((prev) => [
          {
            id: getFormatedUUID(),
            tables: [],
            select_root: "",
            alias: "",
          },
          ...prev,
        ]);
        break;
      case "update":
        setTables((prev) => {
          const newTables = [...prev];

          for (const index in newTables) {
            const table = newTables[index];
            if (table.id === payload.id) {
              for (const k in payload) {
                table[k] = payload[k];
              }
              break;
            }
          }

          return newTables;
        });
        break;
      case "delete":
        setTables((prev) => prev.filter((table) => table.id !== payload.id));
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    updateSelectedComponent(tables, path.split("."));
  }, [tables]);

  return (
    <section>
      <button onClick={() => handleChange({ type: "add" })}>Them bang</button>
      {tables.map(({ id, tables = [], select_root, alias }) => (
        <section key={id}>
          <button
            onClick={() => handleChange({ type: "delete", payload: { id } })}
          >
            Xoa
          </button>
          <label>Alias: </label>
          <input
            type="text"
            value={alias}
            onChange={({ target: { value } }) => {
              handleChange({
                type: "update",
                payload: { id, alias: value },
              });
            }}
          />
          <JoiningTableItem
            DATA={{ select_root, tables }}
            tables={initialTables}
            handleUpdate={({ tables, select_root }) => {
              handleChange({
                type: "update",
                payload: { id, tables, select_root },
              });
            }}
            selectedCpn={selectedCpn}
          />
        </section>
      ))}
    </section>
  );
}

export const SelectNestedTables = memo(Component);
