import { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import functions from "../../../../../redux/configs/functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
  faCirclePlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

export const SearchContainer = ({
  options,
  FIELDS = [],
  MAPPED_FIELDS,
  onChange,
  default_value = [],
  calculates,
}) => {
  const [fields, setFields] = useState(default_value);
  const { getFormatedUUID } = functions;

  const FIELD_OPTIONS = useMemo(() => {
    return FIELDS.map(({ field_name, fomular_alias }) => ({
      label: `${field_name}-${fomular_alias}`,
      value: fomular_alias,
    }));
  }, [FIELDS]);

  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, zIndex: 9999 }),
  };
  const handleChange =
    ({ type, payload }) =>
    () => {
      switch (type) {
        case "add":
          setFields((prev) => [{ id: getFormatedUUID() }, ...prev]);
          break;
        case "update":
          setFields((prev) => {
            const newFields = [...prev];
            const index = newFields.findIndex(
              (field) => field.id === payload.id
            );

            if (index > -1) {
              for (const k in payload) {
                newFields[index][k] = payload[k];
              }
            }

            return newFields;
          });
          break;
        case "delete":
          setFields((prev) => prev.filter(({ id }) => id !== payload.id));
          break;
        default:
          break;
      }
    };

  useEffect(() => {
    onChange(fields);
  }, [fields]);

  return (
    <>
      <FontAwesomeIcon
        className="style-cursor"
        style={{ marginLeft: "5px", fontSize: "16px" }}
        icon={faCirclePlus}
        onClick={handleChange({ type: "add" })}
      />
      <div style={{ border: "1px solid #ccc", borderRadius: ".75em" }}>
        {fields.map(
          ({ id, label, value, isSelect, isRegexSearching, searchedField }) => (
            <div className="row p-2" key={id}>
              <div
                className="col-md-10"
                style={{ borderRight: "1px #ccc solid" }}
              >
                <div style={{ zIndex: "1" }}>
                  <Select
                    className="mt-2 mb-2"
                    styles={customStyles}
                    options={options}
                    isClearable
                    value={{ label, value }}
                    onChange={(option) => {
                      let alias = option.label
                        ?.split("-$")
                        ?.at(-1)
                        ?.split(".")
                        ?.at(-1);
                      alias = alias[0] === "$" ? alias.slice(1) : alias;
                      const field = MAPPED_FIELDS.find(
                        ({ fomular_alias }) => fomular_alias === alias
                      );
                      const calculate = calculates.find(
                        (item) => item.label === alias
                      );

                      if (field) {
                        option = { ...option, field, id };

                        handleChange({
                          type: "update",
                          payload: option,
                        })();
                      } else if (calculate) {
                        const field = {
                          field_name: calculate.value,
                          fomular_alias: calculate.value,
                        };
                        option = { ...option, field, id };

                        handleChange({
                          type: "update",
                          payload: option,
                        })();
                      }
                    }}
                  />
                  <Select
                    className="mt-2 mb-2"
                    styles={customStyles}
                    options={FIELD_OPTIONS}
                    isClearable
                    value={searchedField}
                    onChange={(option) => {
                      handleChange({
                        type: "update",
                        payload: { id, searchedField: option },
                      })();
                    }}
                  />
                </div>
                <div>
                  <label className="mr-2">IsSelect</label>
                  <input
                    type="checkbox"
                    checked={isSelect}
                    onChange={({ target: { checked } }) => {
                      handleChange({
                        type: "update",
                        payload: { id, isSelect: checked },
                      })();
                    }}
                  />
                </div>
                <div>
                  <label className="mr-2">IsRegexSearching</label>
                  <input
                    type="checkbox"
                    checked={isRegexSearching}
                    onChange={({ target: { checked } }) => {
                      handleChange({
                        type: "update",
                        payload: { id, isRegexSearching: checked },
                      })();
                    }}
                  />
                </div>
              </div>
              <div
                className="col-md-2 p-0"
                style={{ textAlign: "center", margin: "auto" }}
              >
                <FontAwesomeIcon
                  className="style-cursor"
                  icon={faTrash}
                  style={{
                    color: "red",
                    fontSize: "20px",
                  }}
                  onClick={handleChange({ type: "delete", payload: { id } })}
                />
              </div>
            </div>
          )
        )}
      </div>
    </>
  );
};
