import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function AddTables({
  initialPossibleTables = [],
  initialSelectedTables = [],
  users,
  setIsAddTable,
  isAddTable,
}) {
  const { lang } = useSelector((state) => state);
  const [selectedTables, setSelectedTables] = useState(initialSelectedTables);
  const [possibleTables, setPossibleTables] = useState(initialPossibleTables);

  useEffect(() => {
    setPossibleTables((prev) =>
      prev.filter(
        (item) => !selectedTables.some((table) => table.id === item.id)
      )
    );
  }, [selectedTables]);

  const handleChange = (e) => {
    const selectedTable = e.target.value;
    setSelectedTables((prevSelectedTables) => {
      return [...prevSelectedTables, JSON.parse(selectedTable)];
    });
  };

  return (
    <div class="modal no-select-modal show" style={{ display: "block" }}>
      <div class="modal-dialog modal-dialog-center">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">{lang["select table"]}</h4>
            <button
              type="button"
              class="close"
              onClick={() => {
                setIsAddTable({ state: false, fn: () => {} });
              }}
            >
              &times;
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div className={`form-group col-lg-12`}>
                <label>
                  {lang["table name"]} <span className="red_star">*</span>
                </label>
                <select className="form-control" onChange={handleChange}>
                  <option value="">{lang["choose"]}</option>
                  {possibleTables.map((table) => (
                    <option key={table.id} value={JSON.stringify(table)}>
                      {table.table_name}
                    </option>
                  ))}
                </select>

                {selectedTables.length > 0 && (
                  <div className={`form-group col-lg-12 mt-2`}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <label>
                        {lang["selected table"]}:{" "}
                        <span className="red_star">*</span>
                      </label>
                      <button
                        class="btn btn-danger mb-2"
                        onClick={() => {
                          setSelectedTables([]);
                          setPossibleTables(initialPossibleTables);
                        }}
                      >
                        {lang["deleteall"]}
                      </button>
                    </div>
                    <div className="outerBox">
                      {selectedTables.map((table) => (
                        <div key={table.id} className="innerBox">
                          {table.table_name}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <div class="form-group col-md-12">
                <label>{lang["creator"]}</label>
                <input
                  class="form-control"
                  type="text"
                  value={users.fullname}
                  readOnly
                ></input>
              </div>
              <div class="form-group col-md-12">
                <label>{lang["time"]}</label>
                <input
                  class="form-control"
                  type="text"
                  value={new Date().toISOString().substring(0, 10)}
                  readOnly
                ></input>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-success"
              data-dismiss="modal"
              onClick={() => {
                isAddTable.fn(selectedTables);
                setIsAddTable({ state: false, fn: () => {} });
              }}
            >
              {" "}
              {lang["btn.create"]}
            </button>
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-danger"
              onClick={() => setIsAddTable({ state: false, fn: () => {} })}
            >
              {lang["btn.close"]}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export { AddTables };
