import { faPlusCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { memo, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { ItemTypeModal } from "./ItemTypeModal";
import functions from "../../../../../redux/configs/functions";
import { Conditions } from "./Conditions";

function Component(props) {
  const {
    getPropByPath,
    updateSelectedComponent,
    path = "",
    selectedCpn,
    id,
  } = props;
  const { getFormatedUUID } = functions;

  const [FieldOptions, TableOptions] = useMemo(() => {
    const FieldOptions = [];
    const TableOptions = [];

    const tables = selectedCpn?.props?.joiningTables;

    for (const index in tables) {
      const { select_root, alias } = tables[index];
      for (const k in select_root) {
        FieldOptions.push({ label: `${alias}-${k}`, value: `${alias}-${k}` });
      }
      TableOptions.push({ label: alias, value: alias });
    }
    return [FieldOptions, TableOptions];
  }, [selectedCpn?.props?.joiningTables]);

  const [mergingTables, setMergingTables] = useState(
    getPropByPath(path.split("."), selectedCpn) || []
  );

  const handleChange = ({ type, payload }) => {
    switch (type) {
      case "add":
        setMergingTables((prev) => [
          ...prev,
          {
            id: getFormatedUUID(),
            target: {},
            source: {},
            conditions: [],
          },
        ]);
        break;
      case "update":
        setMergingTables((prev) => {
          const newMergingTables = [...prev];

          for (const index in newMergingTables) {
            const { id } = newMergingTables[index];
            if (id === payload.id) {
              for (const k in payload) {
                newMergingTables[index][k] = payload[k];
              }
              break;
            }
          }

          return newMergingTables;
        });
        break;
      case "delete":
        setMergingTables((prev) => prev.filter(({ id }) => id !== payload.id));
        break;
    }
  };

  useEffect(() => {
    updateSelectedComponent(mergingTables, path.split("."));
  }, [mergingTables]);

  return (
    <section>
      <FontAwesomeIcon
        className=""
        icon={faPlusCircle}
        onClick={() => {
          handleChange({ type: "add" });
        }}
      />
      {mergingTables.map(({ id, conditions, target, source }) => {
        return (
          <section key={id}>
            <section className="d-flex justify-content-between mb-4 px-2">
              <section>
                <p>target</p>
                <Select
                  options={TableOptions}
                  value={target}
                  onChange={(target) => {
                    handleChange({ type: "update", payload: { id, target } });
                  }}
                />
              </section>
              <section>
                <p>source</p>
                <Select
                  options={TableOptions}
                  value={source}
                  onChange={(source) => {
                    handleChange({ type: "update", payload: { id, source } });
                  }}
                />
              </section>
            </section>

            <Conditions
              initials={conditions}
              FieldOptions={FieldOptions}
              onChange={(conditions) => {
                handleChange({ type: "update", payload: { id, conditions } });
              }}
            />
          </section>
        );
      })}
    </section>
  );
}
export const MergeTable = memo(Component);
