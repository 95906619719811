import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import { ReactSortable } from "react-sortablejs";
import React, { useEffect, useState } from "react";

export default function Component(props) {
  const { cache, preview, page, pages } = useSelector((state) => state);
  const {
    id,
    zIndex,
    appendChildComponent,
    children,
    parent,
    renderFrontLiner,
    renderBackLiner,
    modifyChildren,
    leaves,
    style,
  } = props;
  const dispatch = useDispatch();

  const [isLists, setLists] = useState(
    page?.newChild ? page?.newChild : leaves
  );

  useEffect(() => {
    const form = page.component.find(({ name }) => name === "form");
    if (form?.children && form.children !== isLists) {
      const { children, ...props } = form;
      setLists(children);
    }
    // setLists(page?.component);
  }, [page?.component]);

  const isActive = () => {
    const { activeComponent, hoverComponent } = cache;
    if (activeComponent.indexOf(id) !== -1 || hoverComponent == id) {
      return true;
    }
    return false;
  };

  const atLeastOneChildIsNotUndefined = () => {
    const filtedChildren = children.filter((c) => c != undefined);
    return filtedChildren.length;
  };

  const SwitchingState = () => {
    const { activeComponent } = cache;

    if (activeComponent != id) {
      dispatch({
        branch: "design-ui",
        type: "setActiveComponent",
        payload: {
          id,
        },
      });

      $("#property-trigger").click();
    }
  };

  const ComponentHover = () => {
    dispatch({
      branch: "design-ui",
      type: "setHoverComponent",
      payload: {
        id,
      },
    });
  };

  const FormAppendsChild = () => {
    appendChildComponent(id);
  };

  const getDecsButtonRecursive = (components) => {
    const buttons = [];
    for (let i = 0; i < components.length; i++) {
      const cpn = components[i];
      const { name } = cpn;
      if (name == "button") {
        buttons.push(cpn);
      } else {
        const { children } = cpn;
        if (children) {
          const descButtons = getDecsButtonRecursive(children);
          buttons.push(...descButtons);
        }
      }
    }
    return buttons;
  };

  const findButtonByID = (components, id) => {
    const buttons = [];
    for (let i = 0; i < components.length; i++) {
      const cpn = components[i];

      if (cpn.id == id) {
        buttons.push(cpn);
      } else {
        const { children } = cpn;
        if (children) {
          const descButtons = findButtonByID(children, id);
          buttons.push(descButtons);
        }
      }
    }
    return buttons[0];
  };

  if (preview) {
    return (
      <div className="design-zone-container" style={{ ...style, zIndex }}>
        {children}
      </div>
    );
  } else {
    return (
      <div className="design-zone-container" style={{ ...style, zIndex }}>
        <ReactSortable
          list={isLists}
          setList={(list) => {
            modifyChildren(id, list);
            dispatch({
              branch: "design-ui",
              type: "updateOrderFormFields",
              payload: {
                component_id: id,
                page_id: page,
                children: list,
              },
            });
            setLists(list);
          }}
        >
          {children}
        </ReactSortable>
      </div>
    );
  }
}
