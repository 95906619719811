import functions from "../functions";
import lang from "../property_lang";

const { getFormatedUUID } = functions;
const barcode_activation = [
  {
    id: getFormatedUUID(),
    label: lang["props.title"],
    type: "text",
    path: "props.name",
  },

  {
    id: getFormatedUUID(),
    label: lang["props.tables"],
    type: "selectTable",
    path: "props.table",
    fieldsPath: "props.fields",
    display_value: "table_name",
    // childOf: {
    //     prop_id: "prop_1",
    //     caseIf: "database"
    // }
  },
  {
    id: "api-selection",
    label: "Api",
    type: "apiSelection",
    path: "props.publicAPI",
    url: "/apis/v/[version_id]",
    params: ["version_id"],
    api_data: "data.apis",
    fields: [
      {
        from: "api_id",
        to: "api",
      },
      {
        from: "api_name",
        to: "api_name",
      },
      {
        from: "fields",
        to: "fields",
      },
    ],
    display_value: "api_name",
  },
  {
    id: getFormatedUUID(),
    label: lang["props.criterias"],
    type: "selfSelection",
    path: "props.criteria",
    data: "props.table.fields",
    fields: [
      {
        from: "id",
        to: "id",
      },
      {
        from: "field_name",
        to: "field_name",
      },
      {
        from: "fomular_alias",
        to: "fomular_alias",
      },
    ],
    display_value: "field_name",
  },

  {
    id: getFormatedUUID(),
    label: lang["props.choosemaster"],
    type: "chooseMaster",
    path: "props.master",
    table_path: "props.table",
  },

  {
    id: getFormatedUUID(),
    label: lang["style.padding"],
    type: "text",
    path: "props.style.padding",
  },

  {
    id: getFormatedUUID(),
    label: lang["style.margin"],
    type: "text",
    path: "props.style.margin",
  },
];
export default barcode_activation;
