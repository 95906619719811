import {
  faAlignCenter,
  faAlignJustify,
  faAlignLeft,
  faAlignRight,
  faItalic,
  faBold,
  faUnderline,
  faStrikethrough,
} from "@fortawesome/free-solid-svg-icons";
import functions from "../functions";
import lang from "../property_lang";

const { getFormatedUUID } = functions;

const entry = [
  {
    id: getFormatedUUID(),
    label: lang["props.title"],
    type: "text",
    path: "props.title.content",
  },

  {
    id: getFormatedUUID(),
    label: lang["props.visible"],
    type: "bool",
    path: "props.title.visible",
    if_true: {
      value: true,
      label: lang["props.visible.show"],
    },
    if_false: {
      value: false,
      label: lang["props.visible.hide"],
    },
  },

  {
    id: getFormatedUUID(),
    label: lang["props.required"],
    type: "bool",
    path: "props.required",
    if_true: {
      value: true,
      label: lang["props.required.required"],
    },
    if_false: {
      value: false,
      label: lang["props.required.optional"],
    },
  },

  {
    id: getFormatedUUID(),
    label: lang["props.editor"],
    type: "bool",
    path: "props.editor",
    if_true: {
      value: true,
      label: lang["props.visible.show"],
    },
    if_false: {
      value: false,
      label: lang["props.visible.hide"],
    },
  },

  {
    id: getFormatedUUID(),
    label: "Giá trị mặc định",
    type: "text",
    path: "props.default_value",
  },

  {
    id: getFormatedUUID(),
    label: lang["props.textarea"],
    type: "bool",
    path: "props.textarea",
    if_true: {
      value: true,
      label: lang["props.visible.show"],
    },
    if_false: {
      value: false,
      label: lang["props.visible.hide"],
    },
  },

  {
    id: getFormatedUUID(),
    label: lang["props.show_edit"],
    type: "bool",
    path: "props.show_edit",
    if_true: {
      value: true,
      label: lang["props.visible.show"],
    },
    if_false: {
      value: false,
      label: lang["props.visible.hide"],
    },
  },

  {
    id: getFormatedUUID(),
    label: lang["style.fontsize"],
    type: "number",
    path: "props.labelStyle.fontSize",
  },
  {
    id: getFormatedUUID(),
    label: lang["style.color"],
    type: "color",
    path: "props.labelStyle.color",
  },
  {
    id: getFormatedUUID(),
    label: lang["style.alignment"],
    type: "iconicSwitchingGroup",
    path: "props.labelStyle.textAlign",
    defaultValue: "left",
    buttons: [
      {
        id: getFormatedUUID(),
        icon: faAlignLeft,
        value: "left",
      },
      {
        id: getFormatedUUID(),
        icon: faAlignCenter,
        value: "center",
      },
      {
        id: getFormatedUUID(),
        icon: faAlignRight,
        value: "right",
      },
      {
        id: getFormatedUUID(),
        icon: faAlignJustify,
        value: "justify",
      },
    ],
  },
  {
    id: getFormatedUUID(),
    label: lang["style.italic"],
    type: "iconicSwitching",
    path: "props.labelStyle.fontStyle",
    values: ["unset", "italic"],
    icon: faItalic,
  },
  {
    id: getFormatedUUID(),
    label: lang["style.bold"],
    type: "iconicSwitching",
    path: "props.labelStyle.fontWeight",
    values: ["unset", "bold"],
    icon: faBold,
  },
  {
    id: getFormatedUUID(),
    label: lang["style.underline"],
    type: "iconicSwitching",
    path: "props.labelStyle.textDecoration",
    values: ["unset", "underline"],
    icon: faUnderline,
  },

  {
    id: getFormatedUUID(),
    label: lang["style.margin"],
    type: "text",
    path: "props.labelStyle.margin",
  },
  {
    id: getFormatedUUID(),
    label: lang["style.padding"],
    type: "text",
    path: "props.labelStyle.padding",
  },

  {
    id: getFormatedUUID(),
    label: lang["props.placeholder"],
    type: "text",
    path: "props.placeholder.content",
  },
  {
    id: "api-selection",
    label: "Api GET",
    type: "apiSelection",
    path: "props.apiGet",
    url: "/apis/v/[version_id]",
    params: ["version_id"],
    api_data: "data.apis",
    fields: [
      {
        from: "api_id",
        to: "api",
      },
      {
        from: "api_name",
        to: "api_name",
      },
      {
        from: "fields",
        to: "fields",
      },
    ],
    display_value: "api_name",
  },
  {
    id: "api-selection",
    label: "Api PUT",
    type: "apiSelection",
    path: "props.apiPut",
    url: "/apis/v/[version_id]",
    params: ["version_id"],
    api_data: "data.apis",
    fields: [
      {
        from: "api_id",
        to: "api",
      },
      {
        from: "api_name",
        to: "api_name",
      },
      {
        from: "fields",
        to: "fields",
      },
    ],
    display_value: "api_name",
  },
  {
    id: "api-selection",
    label: "Api",
    type: "apiSelection",
    path: "props.api",
    url: "/apis/v/[version_id]",
    params: ["version_id"],
    api_data: "data.apis",
    fields: [
      {
        from: "api_id",
        to: "api",
      },
      {
        from: "api_name",
        to: "api_name",
      },
      {
        from: "fields",
        to: "fields",
      },
    ],
    display_value: "api_name",
  },

  {
    id: getFormatedUUID(),
    label: lang["props.show_table"],
    type: "SelectDisplayField",
    path: "props.show_table",
  },

  {
    id: getFormatedUUID(),
    label: lang["props.variable_name"],
    type: "text",
    path: "props.variable_name",
    onlyExistsIn: [{ name: "form", type: "cascading" }],
  },

  {
    id: getFormatedUUID(),
    label: lang["props.displayField"],
    type: "SelectDisplayField",
    path: "props.displayField",
  },

  {
    id: getFormatedUUID(),
    label: lang["style.order"],
    type: "number",
    path: "props.flex.order",
    onlyExistsIn: [{ name: "flex", type: "direct" }],
  },
  {
    id: getFormatedUUID(),
    label: lang["style.flexgrow"],
    type: "text",
    path: "props.flex.flexGrow",
    onlyExistsIn: [{ name: "flex", type: "direct" }],
  },
];
export default entry;
