import {
  faAngleRight,
  faAngleUp,
  faPlusCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { memo, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import functions from "../../../../../redux/configs/functions";
const CHARTS = {
  table_chart: 1,
  c_chart: 1,
};

function Component(props) {
  const { page, tables: root_tables, lang } = useSelector((s) => s);
  const { getFormatedUUID } = functions;

  const { updateSelectedComponent, path, selectedCpn, getPropByPath, path2 } =
    props;
  const initial_charts = selectedCpn?.props?.charts || [];

  const [selectedCharts, setSelectedCharts] = useState(initial_charts);

  const charts = useMemo(() => {
    return (
      page.component.reduce((prev, component) => {
        if (CHARTS[component.name]) {
          prev.push(component);
        } else if (component.name === "grid") {
          const list_chart = component.children.filter(
            (component) => CHARTS[component.name]
          );
          prev.push(...list_chart);
        }
        return prev;
      }, []) || []
    );
  }, selectedCharts);

  const convertOptionsFromTable = (table_alias, alias) => {
    const options = [];

    const table = root_tables.find((item) => item.table_alias === table_alias);
    if (table) {
      const { fields } = table;
      for (const index_field in fields) {
        const field = fields[index_field];
        const { field_name, fomular_alias } = field;

        options.push({
          label: `${alias}-${field_name}-${fomular_alias}`,
          value: fomular_alias,
          key: alias,
          field,
        });
      }
    }
    return options;
  };

  const FIELD_OPTIONS = useMemo(() => {
    const options = [];
    const table = {};

    for (const i in charts) {
      const {
        props: {
          joiningTable: { tables },
          joiningTables,
        },
      } = charts[i];

      for (const table_index in tables) {
        const { left_table, right_table, alias = {} } = tables[table_index];
        const left_table_name = alias["left_table"] || left_table;
        const right_table_name = alias["right_table"] || right_table;

        if (!table[left_table_name]) {
          table[left_table_name] = 1;

          options.push(...convertOptionsFromTable(left_table, left_table_name));
        }

        if (!table[right_table_name]) {
          table[right_table_name] = 1;
          options.push(
            ...convertOptionsFromTable(right_table, right_table_name)
          );
        }
      }

      for (const index in joiningTables) {
        const { tables } = joiningTables[index];
        for (const table_index in tables) {
          const { left_table, right_table, alias = {} } = tables[table_index];

          const left_table_name = alias["left_table"] || left_table;
          const right_table_name = alias["right_table"] || right_table;

          if (!table[left_table_name]) {
            table[left_table_name] = 1;

            options.push(
              ...convertOptionsFromTable(left_table, left_table_name)
            );
          }

          if (!table[right_table_name]) {
            table[right_table_name] = 1;
            options.push(
              ...convertOptionsFromTable(right_table, right_table_name)
            );
          }
        }
      }
    }

    return options;
  }, [charts]);

  const REFERENCE_FIELD_OPTIONS = useMemo(() => {
    const options = [];
    root_tables?.map((table) => {
      const { table_alias, fields, table_name } = table;

      for (const index in fields) {
        const { field_name, fomular_alias } = fields[index];

        options.push({
          label: `${field_name}-${table_alias}-${table_name}`,
          value: fomular_alias,
          field: fields[index],
        });
      }
    });
    return options;
  }, [root_tables]);

  const [showSearch, setShowSearch] = useState(false);
  const [showFields, setShowFields] = useState(false);

  const handleShowSearch = () => {
    setShowSearch(!showSearch);
  };
  const handleShowFields = () => {
    setShowFields(!showFields);
  };

  useEffect(() => {
    updateSelectedComponent(selectedCharts, path.split("."));
  }, [selectedCharts]);

  const [searchingFields, setSearchingFields] = useState(
    getPropByPath(path2.split("."), selectedCpn) || []
  );

  useEffect(() => {
    updateSelectedComponent(searchingFields, path2.split("."));
  }, [searchingFields]);

  const handleChangeSearchingFields = ({ type, payload }) => {
    switch (type) {
      case "add":
        setSearchingFields((prev) => [
          { id: getFormatedUUID(), label: "", fields: [], dateType: {} },
          ...prev,
        ]);
        break;
      case "update":
        setSearchingFields((prev) => {
          const newSearchingFields = [...prev];
          for (const index in newSearchingFields) {
            const { id } = newSearchingFields[index];
            for (const k in payload) {
              if (id === payload.id) {
                newSearchingFields[index][k] = payload[k];
              }
            }
          }
          return newSearchingFields;
        });
        break;
      case "delete":
        setSearchingFields((prev) =>
          prev.filter((field) => field.id !== payload.id)
        );
        break;
    }
  };

  return (
    <div className="padding-1rem">
      <div className="c-chart p-0">
        <div
          className={` ${showSearch ? "chart-header pb-2" : "chart-header-of"}`}
          onClick={handleShowSearch}
        >
          <div className="chart-label">
            <FontAwesomeIcon
              icon={showSearch ? faAngleUp : faAngleRight}
              className="me-2"
            />
            {lang["Search"]}
          </div>
        </div>
        {showSearch && (
          <section>
            <div className="p-2">
              <Select
                value={selectedCharts}
                isMulti
                name="colors"
                onChange={(charts) => {
                  setSelectedCharts(charts);
                }}
                options={charts.map(({ props: { content }, id }) => ({
                  label: content,
                  value: id,
                }))}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </div>
            <div className="joining-container p-0">
              <div
                // className="joining-header"
                className={`${
                  showFields ? "joining-header" : " joining-header-of"
                }`}
                onClick={handleShowFields}
              >
                <div className="chart-label m-2">
                  <FontAwesomeIcon
                    icon={showFields ? faAngleUp : faAngleRight}
                    className="me-2"
                  />
                  {lang["Field"]}
                </div>
              </div>
              {showFields && (
                <div className="p-1">
                  <div className="accordion" id="accordionJoining">
                    <div className="accordion-body p-2">
                      <button
                        className="btn btn-success ml-2 mb-2"
                        onClick={() => {
                          handleChangeSearchingFields({ type: "add" });
                        }}
                      >
                        Thêm
                      </button>
                      {searchingFields.map(
                        ({
                          id,
                          label,
                          fields,
                          dateType,
                          reference_field,
                          search_type,
                          isMulti,
                        }) => {
                          return (
                            <section key={id}>
                              <FontAwesomeIcon
                                icon={faTrash}
                                className="btn-remove-fields"
                                onClick={() => {
                                  handleChangeSearchingFields({
                                    type: "delete",
                                    payload: { id },
                                  });
                                }}
                              />
                              <div class="mb-3 d-flex">
                                <label
                                  for="exampleInputEmail1"
                                  class="form-label"
                                >
                                  Tên:
                                </label>
                                <input
                                  type="text"
                                  value={label}
                                  onChange={({ target: { value } }) => {
                                    handleChangeSearchingFields({
                                      type: "update",
                                      payload: { id, label: value },
                                    });
                                  }}
                                  class="form-control"
                                  id="exampleInputEmail1"
                                />
                              </div>
                              <section className="mt-2 d-flex align-items-center">
                                <label className="col-sm-5 p-0">
                                  Date type:{" "}
                                </label>
                                <Select
                                  className="col-sm-7"
                                  isClearable={true}
                                  options={(() => {
                                    const options = [
                                      { label: "Month", value: "Month" },
                                      { label: "Year", value: "Year" },
                                    ];
                                    return options;
                                  })()}
                                  onChange={(dateType) => {
                                    handleChangeSearchingFields({
                                      type: "update",
                                      payload: { id, dateType },
                                    });
                                  }}
                                  value={dateType}
                                />
                              </section>
                              <div class="mb-3">
                                <section className="d-flex">
                                  <label class="form-label">
                                    Trường tìm kiếm
                                  </label>
                                  <div
                                    className="add-icon"
                                    onClick={() => {
                                      handleChangeSearchingFields({
                                        type: "update",
                                        payload: {
                                          id,
                                          fields: [
                                            {
                                              id: getFormatedUUID(),
                                              field: {},
                                              reference_field: {},
                                            },
                                            ...fields,
                                          ],
                                        },
                                      });
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faPlusCircle} />
                                  </div>
                                </section>
                                {fields.map(({ id: _id, field }) => {
                                  return (
                                    <section key={id}>
                                      <label>
                                        Field
                                        <FontAwesomeIcon
                                          icon={faTrash}
                                          className="btn-remove-fields"
                                          onClick={() => {
                                            handleChangeSearchingFields({
                                              type: "update",
                                              payload: {
                                                id,
                                                fields: fields.filter(
                                                  (field) => field.id !== _id
                                                ),
                                              },
                                            });
                                          }}
                                        />
                                      </label>
                                      <Select
                                        name="colors"
                                        options={FIELD_OPTIONS}
                                        isClearable={true}
                                        value={field}
                                        onChange={(option) => {
                                          for (const index in fields) {
                                            const field = fields[index];
                                            if (field.id === _id) {
                                              fields[index].field = option;
                                            }
                                          }
                                          handleChangeSearchingFields({
                                            type: "update",
                                            payload: {
                                              id,
                                              fields: fields,
                                            },
                                          });
                                        }}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                      />
                                    </section>
                                  );
                                })}
                                <section>
                                  <section className="d-flex">
                                    <label>Reference Field</label>
                                    <section className="ml-4">
                                      <label>Is Multi</label>
                                      <input
                                        type="checkbox"
                                        checked={isMulti}
                                        onChange={({ target: { checked } }) => {
                                          handleChangeSearchingFields({
                                            type: "update",
                                            payload: {
                                              id,
                                              isMulti: checked,
                                            },
                                          });
                                        }}
                                      />
                                    </section>
                                  </section>
                                  <section className="d-flex">
                                    <label>Search type</label>
                                    <Select
                                      name="colors"
                                      isClearable={true}
                                      onChange={(option) => {
                                        handleChangeSearchingFields({
                                          type: "update",
                                          payload: {
                                            id,
                                            search_type: option,
                                          },
                                        });
                                      }}
                                      value={search_type}
                                      options={[
                                        { label: "Regex", value: "regex" },
                                        {
                                          label: "Full text search",
                                          value: "fullTextSearch",
                                        },
                                      ]}
                                      className="basic-multi-select"
                                      classNamePrefix="select"
                                    />
                                  </section>
                                  <Select
                                    name="colors"
                                    isClearable={true}
                                    onChange={(option) => {
                                      handleChangeSearchingFields({
                                        type: "update",
                                        payload: {
                                          id,
                                          reference_field: option,
                                        },
                                      });
                                    }}
                                    value={reference_field}
                                    options={REFERENCE_FIELD_OPTIONS}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                  />
                                </section>
                              </div>
                            </section>
                          );
                        }
                      )}
                      {/* {state.map(
                        ({ id, key, field, label, value, dateType }) => {
                          return (
                            <section key={id}>
                              <FontAwesomeIcon
                                icon={faTrash}
                                className="btn-remove-fields"
                                onClick={() => {
                                  handleDeleteSearchingField(id);
                                }}
                              />
                              <div class="mb-3 d-flex">
                                <label
                                  for="exampleInputEmail1"
                                  class="form-label"
                                >
                                  Tên:
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  id="exampleInputEmail1"
                                />
                              </div>
                              <div class="mb-3">
                                <label class="form-label">
                                  Trường tìm kiếm
                                </label>
                                <Select
                                  name="colors"
                                  options={FIELD_OPTIONS}
                                  onChange={(option) => {}}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                />
                              </div>
                              <Select
                                name="colors"
                                options={(() => {
                                  const options = [];
                                  for (const index in joinedTables) {
                                    const { key, fields } = joinedTables[index];
                                    fields.map(
                                      ({
                                        field_name,
                                        fomular_alias,
                                        ...props
                                      }) => {
                                        options.push({
                                          label: `${key}-${field_name}-${fomular_alias}`,
                                          value: fomular_alias,
                                          key,
                                          field: {
                                            field_name,
                                            fomular_alias,
                                            ...props,
                                          },
                                        });
                                      }
                                    );
                                  }
                                  return options;
                                })()}
                                onChange={(option) => {
                                  handleUpdateSearchingField(id, option);
                                }}
                                value={{ label, value }}
                                className="basic-multi-select"
                                classNamePrefix="select"
                              />
                              <section className="mt-2 d-flex align-items-center">
                                <label className="col-sm-5 p-0">
                                  Date type:{" "}
                                </label>
                                <Select
                                  className="col-sm-7"
                                  options={(() => {
                                    const options = [
                                      { label: "Month", value: "Month" },
                                      { label: "Year", value: "Year" },
                                    ];
                                    return options;
                                  })()}
                                  onChange={(option) => {
                                    handleUpdateSearchingField(id, option);
                                  }}
                                  value={{
                                    label: dateType,
                                    value: dateType,
                                  }}
                                />
                              </section>
                            </section>
                          );
                        }
                      )} */}
                    </div>
                  </div>
                </div>
              )}
              {/* {
                <div className="p-2">
                  <label>Trường select:</label>
                  <button
                    className="btn btn-success ml-2 mb-2"
                    onClick={handleAddField}
                  >
                    Thêm
                  </button>
                  {Selected_Fields.map(
                    ({ reference_field, field, id, isMulti }) => {
                      return (
                        <section
                          key={id}
                          className="c-chart p-2"
                          style={{ border: "1px grey solid" }}
                        >
                          <label>Field</label>
                          <Select
                            name="colors"
                            onChange={(data) => {
                              handleFieldChange(id, data, "field");
                            }}
                            value={field}
                            options={state?.map(
                              ({ key, fields, label, value, field }) => ({
                                label,
                                value,
                                field,
                              })
                            )}
                            className="basic-multi-select"
                            classNamePrefix="select"
                          />
                          <label>Reference Field</label>
                          <Select
                            name="colors"
                            onChange={(data) => {
                              handleFieldChange(id, data, "reference_field");
                            }}
                            value={reference_field}
                            options={(() => {
                              const options = [];
                              root_tables?.map((table) => {
                                const { table_alias, fields, table_name } =
                                  table;

                                for (const index in fields) {
                                  const { field_name, fomular_alias } =
                                    fields[index];

                                  options.push({
                                    label: `${field_name}-${table_alias}-${table_name}`,
                                    value: fomular_alias,
                                    field: fields[index],
                                  });
                                }
                              });
                              return options;
                            })()}
                            className="basic-multi-select"
                            classNamePrefix="select"
                          />
                          <section>
                            <label>Is Multi</label>
                            <input
                              type="checkbox"
                              checked={isMulti}
                              onChange={({ target: { checked } }) => {
                                handleFieldChange(id, checked, "isMulti");
                              }}
                            />
                          </section>
                          <button
                            className="btn btn-danger mt-2"
                            onClick={() => {
                              handleDelField(id);
                            }}
                          >
                            Xóa
                          </button>
                        </section>
                      );
                    }
                  )}
                </div>
              } */}
            </div>
          </section>
        )}
      </div>
    </div>
  );
}
export const LinkCharts = memo(Component);
