import {
  faAngleRight,
  faAngleUp,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useSelector } from "react-redux";

export const SelectBarcodeSuggestionDisplayFields = (props) => {
  const { path, getPropByPath, updateSelectedComponent, selectedCpn } = props;
  const { lang } = useSelector((s) => s);

  const [isToggleAccordion, setIsToggleAccordion] = useState(false);

  return (
    <div className="padding-1rem">
      <div className="c-chart p-0">
        <div
          className={` ${
            isToggleAccordion ? "chart-header" : "chart-header-of p-0"
          } d-flex flex-warp `}
        >
          <div
            className="chart-label m-2 "
            onClick={() => setIsToggleAccordion((prev) => !prev)}
          >
            <FontAwesomeIcon
              icon={isToggleAccordion ? faAngleUp : faAngleRight}
              className="me-2"
            />

            {lang["Display field"]}
          </div>
          <div className="incon-plus-chart ms-auto">
            <div className="add-icon" onClick={() => {}}>
              <FontAwesomeIcon icon={faPlusCircle} />
            </div>
          </div>
        </div>
        <div className="accordion" id="accordionExample">
          <section></section>
        </div>
      </div>
    </div>
  );
};
