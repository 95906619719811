import functions from "../../functions";
import lang from "../../property_lang";

const { getFormatedUUID } = functions;

const barcodeSuggestion = [
  {
    id: getFormatedUUID(),
    type: "JoiningTable",
    path: "props.joiningTable",
    path2: "props.joiningTables",
    tablespath: "props.joiningTable",
  },
  {
    id: getFormatedUUID(),
    label: "Chọn trường hiển thị",
    type: "SelectDisplayFields",
    path: "props.source.DisplayFields",
    pathTo: "props.to",
    fieldsTo: [
      {
        from: "page_id",
        to: "page_id",
      },
      {
        from: "page_title",
        to: "page_title",
      },
      {
        from: "params",
        to: "params",
      },
    ],
    isPopup: false,
    pathPopup: "props.isPopup",
    pathColor: "props.style.color",
    pathFontSize: "props.style.fontSize",
    pathTextDecoration: "props.style.textDecoration",
  },
  {
    id: getFormatedUUID(),
    type: "MergeTable",
    path: "props.mergeTables",
  },

  {
    id: getFormatedUUID(),
    label: lang["props.table.recordsperpage"],
    type: "number",
    path: "props.visibility.row_per_page",
  },
];
export { barcodeSuggestion };
