import { faPlusCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import functions from "../../../../../redux/configs/functions";
import { ItemTypeModal } from "./ItemTypeModal";

export const Conditions = ({
  initials = [],
  FieldOptions,
  onChange = () => {},
}) => {
  const { getFormatedUUID } = functions;
  const ComparisonOptions = [
    { label: "Bằng", value: "$eq" },
    { label: "Lớn hơn hoặc bằng", value: "$gte" },
    { label: "Nhỏ hơn hoặc bằng", value: "$lte" },
  ];

  const [conditions, setConditions] = useState(initials);
  const [isToggle, setIsToggle] = useState(false);

  useEffect(() => {
    setConditions(conditions);
  }, [initials]);

  useEffect(() => {
    onChange(conditions);
  }, [conditions]);

  const handleChangeConditions = ({ type, payload }) => {
    switch (type) {
      case "add":
        setConditions((prev) => [
          ...prev,
          {
            id: getFormatedUUID(),
            type: payload.type,
            field_1: {},
            field_2: {},
            operator: {},
          },
        ]);
        break;
      case "update":
        setConditions((prev) => {
          const newConditions = [...prev];

          for (const index in newConditions) {
            const { id } = newConditions[index];
            if (id === payload.id) {
              for (const k in payload) {
                newConditions[index][k] = payload[k];
              }
              break;
            }
          }

          return newConditions;
        });
        break;
      case "delete":
        setConditions((prev) => prev.filter(({ id }) => id !== payload.id));
        break;
    }
  };

  return (
    <>
      <section className="d-flex">
        <p>Thêm điều kiện</p>
        <FontAwesomeIcon
          className=""
          icon={faPlusCircle}
          onClick={() => {
            setIsToggle((prev) => !prev);
          }}
        />
        {isToggle ? (
          <ItemTypeModal
            cb={(type) => {
              handleChangeConditions({
                type: "add",
                payload: { type },
              });
              setIsToggle((prev) => !prev);
            }}
          />
        ) : null}
      </section>

      <section className="d-flex flex-column" style={{ gap: "20px" }}>
        {conditions.map(({ id, type, field_1, field_2, operator }) => {
          if (type === "comparison") {
            return (
              <section className="d-flex" style={{ gap: "10px" }} key={id}>
                <Select
                  options={FieldOptions}
                  value={field_1}
                  onChange={(field_1) => {
                    handleChangeConditions({
                      type: "update",
                      payload: { field_1, id },
                    });
                  }}
                />
                <Select
                  options={ComparisonOptions}
                  value={operator}
                  onChange={(operator) => {
                    handleChangeConditions({
                      type: "update",
                      payload: { operator, id },
                    });
                  }}
                />
                <Select
                  options={FieldOptions}
                  value={field_2}
                  onChange={(field_2) => {
                    handleChangeConditions({
                      type: "update",
                      payload: { field_2, id },
                    });
                  }}
                />
                <FontAwesomeIcon
                  className=""
                  icon={faTrash}
                  onClick={() => {
                    handleChangeConditions({
                      type: "delete",
                      payload: { id },
                    });
                  }}
                />
              </section>
            );
          }
          return <p>null</p>;
        })}
      </section>
    </>
  );
};
