import { faPlusCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { SelectWhere } from "../../../../../Components/Conditions/Table/SelectWhere";
import ColorContext from "../../../../api/ContextTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import functions from "../../../../../redux/configs/functions";

export const ConditionsComponent = (
  props = {
    getPropByPath: () => [],
    updateSelectedComponent: () => [],
    selectedCpn: {},
    path: "",
    path2: "",
    path3: "",
    path4: "",
  }
) => {
  const {
    getPropByPath,
    updateSelectedComponent,
    path = "",
    selectedCpn,
    id,
    path2 = "",
    path3 = "",
    path4 = "",
  } = props;
  const {
    page: { component },
  } = useSelector((s) => s);

  const { getFormatedUUID } = functions;

  const mapChildrenOfGrid = (components) => {
    const options = [];

    for (const index in components) {
      const {
        id,
        props: { content, title, ...props },
        children,
        name,
      } = components[index];
      if (name === "grid") {
        options.push(...mapChildrenOfGrid(children));

        options.push({
          label: props.name || name,
          value: id,
        });
      } else {
        options.push({
          label: content || title,
          value: id,
        });
      }
    }
    return options;
  };

  const Components = useMemo(() => {
    const options = [];

    for (const index in component) {
      const {
        id,
        props: { content, title,name: _name, ...props },
        children,
        name,
      } = component[index];
   
      if (name === "grid") {
        options.push({
          label: props.name || name,
          value: id,
        });

        options.push(...mapChildrenOfGrid(children));
      } else {
        options.push({
          label: _name || content || title,
          value: id,
        });
      }
    }
    return options;
  }, [component]);

  const [color, setColor] = useState({});
  const { apis: APIS_ROOT } = useSelector((s) => s);

  const API_OPTIONS = useMemo(() => {
    const options = [];
    for (const index in APIS_ROOT) {
      const api = APIS_ROOT[index];

      options.push({
        label: api.api_name,
        value: api,
      });
    }
    return options;
  }, [APIS_ROOT]);
  const [apis, setApis] = useState(
    getPropByPath(path3.split("."), selectedCpn) || []
  );
  const [conditions, setConditions] = useState(
    getPropByPath(path.split("."), selectedCpn) || []
  );

  const handleChangeApi = ({ type, payload }) => {
    switch (type) {
      case "add":
        setApis((prev) => [
          ...prev,
          { id: getFormatedUUID(), label: "", api: {} },
        ]);
        break;
      case "update":
        setApis((prev) => {
          const newApis = [...prev];

          for (const index in newApis) {
            const { id } = newApis[index];

            if (id === payload.id) {
              for (const k in payload) {
                newApis[index][k] = payload[k];
              }
              break;
            }
          }

          return newApis;
        });
        break;
      case "delete":
        setApis((prev) => prev.filter(({ id }) => id !== payload.id));
        break;
    }
  };

  const handleChangeCondition = ({ type, payload }) => {
    switch (type) {
      case "add":
        setConditions((prev) => [
          ...prev,
          {
            id: getFormatedUUID(),
            conditions: [],
            failComponents: [],
            successComponents: [],
            isContinue: false,
            isBreak: false,
          },
        ]);
        break;
      case "update":
        setConditions((prev) => {
          const newConditions = [...prev];

          for (const index in newConditions) {
            const { id } = newConditions[index];

            if (id === payload.id) {
              for (const k in payload) {
                newConditions[index][k] = payload[k];
              }
              break;
            }
          }

          return newConditions;
        });
        break;
      case "delete":
        setConditions((prev) => prev.filter(({ id }) => id !== payload.id));
        break;
    }
  };

  useEffect(() => {
    updateSelectedComponent(apis, path3.split("."));
  }, [apis]);
  useEffect(() => {
    updateSelectedComponent(conditions, path.split("."));
  }, [conditions]);

  return (
    <section>
      <section>
        <section className="d-flex align-items-center">
          <label>Thêm api</label>
          <div className="btn-add-condition  icon-end col-2">
            <FontAwesomeIcon
              icon={faPlusCircle}
              onClick={() => handleChangeApi({ type: "add" })}
            />
          </div>
        </section>
        <section>
          {apis.map(({ id, label, api }) => {
            return (
              <div key={id}>
                <div class="input-group mb-3">
                  <span class="input-group-text">Alias</span>
                  <input
                    type="text"
                    class="form-control"
                    value={label}
                    onChange={({ target: { value: label } }) => {
                      handleChangeApi({
                        type: "update",
                        payload: { id, label },
                      });
                    }}
                  />
                  <div className="col-2 icon-end  pe-0">
                    <FontAwesomeIcon
                      className="btn-remove-fields"
                      icon={faTrash}
                      onClick={() => {
                        handleChangeApi({ type: "delete", payload: { id } });
                      }}
                    />
                  </div>
                </div>
                <Select
                  options={API_OPTIONS}
                  value={api}
                  onChange={(api) => {
                    handleChangeApi({ type: "update", payload: { id, api } });
                  }}
                />
              </div>
            );
          })}
        </section>
      </section>
      <section className="d-flex align-items-center">
        <label>Thêm điều kiện</label>
        <div className="btn-add-condition  icon-end col-2">
          <FontAwesomeIcon
            icon={faPlusCircle}
            onClick={() => handleChangeCondition({ type: "add" })}
          />
        </div>
      </section>
      {conditions.map(
        ({
          id,
          conditions,
          isContinue,
          isBreak,
          successComponents,
          failComponents,
        }) => {
          return (
            <section key={id}>
              <div className="col-2 icon-end  pe-0">
                <FontAwesomeIcon
                  className="btn-remove-fields"
                  icon={faTrash}
                  onClick={() => {
                    handleChangeCondition({ type: "delete", payload: { id } });
                  }}
                />
              </div>
              <ColorContext.Provider value={{ color, setColor }}>
                <SelectWhere
                  default_value={conditions}
                  left_fields={[]}
                  right_fields={[]}
                  onChange={(conditions) => {
                    handleChangeCondition({
                      type: "update",
                      payload: { id, conditions },
                    });
                  }}
                />
              </ColorContext.Provider>
              <section>
                <section className="d-flex justify-content-between">
                  <section>
                    <label>Is continue:</label>
                    <input
                      type="checkbox"
                      checked={isContinue}
                      onChange={({ target: { checked: isContinue } }) => {
                        handleChangeCondition({
                          type: "update",
                          payload: { id, isContinue },
                        });
                      }}
                    />
                  </section>
                  <section>
                    <label>Is Break:</label>
                    <input
                      type="checkbox"
                      checked={isBreak}
                      onChange={({ target: { checked: isBreak } }) => {
                        handleChangeCondition({
                          type: "update",
                          payload: { id, isBreak },
                        });
                      }}
                    />
                  </section>
                </section>
                <section>
                  <p>Điều kiện đúng</p>
                  <Select
                    options={Components}
                    isMulti={true}
                    value={successComponents}
                    onChange={(successComponents) => {
                      handleChangeCondition({
                        type: "update",
                        payload: { id, successComponents },
                      });
                    }}
                  />
                </section>
                <section>
                  <p>Điều kiện sai</p>
                  <Select
                    options={Components}
                    isMulti={true}
                    value={failComponents}
                    onChange={(failComponents) => {
                      handleChangeCondition({
                        type: "update",
                        payload: { id, failComponents },
                      });
                    }}
                  />
                </section>
              </section>
            </section>
          );
        }
      )}
    </section>
  );
};
