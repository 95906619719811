import { memo, useEffect, useState } from "react";
import Select from "react-select";
import { TableContainer } from "./Table/TableContainer";

function Component({
  onChange,
  default_value,
  label,
  type = { label: "Text", value: "text" },
  tables,
  table = {},
}) {
  const [state, setState] = useState({
    type,
    value: default_value,
    from_table: table,
  });

  useEffect(() => {
    onChange(state);
  }, [state]);

  return (
    <section className="row d-flex flex-wrap align-items-center ">
      <div className="col-3 " style={{ padding: " 0 0 0 16px" }}>
        {" "}
        <label>{label}: </label>
      </div>
      <div className="col-9 d-flex justify-content-between">
        {state.type.value === "text" ? (
          <input
            className="form-control"
            type="text"
            onChange={({ target: { value } }) => {
              setState((prev) => ({ ...prev, value }));
            }}
            value={default_value}
          />
        ) : (
          <section className="col-8">
            <TableContainer
              tables={tables}
              default_value={state.from_table}
              onChange={(data) => {
                setState((prev) => ({ ...prev, from_table: data }));
                console.log("CHANGE DATA", data);
              }}
            />
          </section>
        )}
        <Select
          className="col-4"
          value={state.type}
          onChange={(option) => {
            setState((prev) => ({ ...prev, type: option }));
          }}
          options={[
            { label: "Table", value: "table" },
            { label: "Text", value: "text" },
          ]}
        />
      </div>
    </section>
  );
}

const SetValue = memo(Component, (oldProps, newProps) => {});
export { SetValue };
