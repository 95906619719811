import React, { memo } from "react";
import { JoiningTableItem } from "./JoiningTable";
import { JoiningTablesItem } from "./JoiningTables";

function JoiningTableComponent(
  props = {
    getPropByPath: () => [],
    updateSelectedComponent: () => [],
    selectedCpn: {},
    path: "",
    path2: "",
  }
) {
  const {
    getPropByPath,
    updateSelectedComponent,
    path,
    path2 = "",
    selectedCpn,
    id,
  } = props;

  const DATA = getPropByPath(path.split("."), selectedCpn);
  const DATA2 = getPropByPath(path2.split("."), selectedCpn);

  return (
    <section>
      <JoiningTableItem
        DATA={DATA}
        handleUpdate={(data) => updateSelectedComponent(data, path.split("."))}
        selectedCpn={selectedCpn}
      />
      <JoiningTablesItem
        DATA={DATA2}
        joining_field={getPropByPath(["props", "joining_field"], selectedCpn)}
        handleUpdate={(data) => updateSelectedComponent(data, path2.split("."))}
        handleUpdateJoiningField={(option) => {
          updateSelectedComponent([option], ["props", "joining_field"]);
        }}
        selectedCpn={selectedCpn}
      />
    </section>
  );
}
export const JoiningTable = memo(JoiningTableComponent);
