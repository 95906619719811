import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faCog,
  faTrash,
  faArrowUpRightFromSquare,
  faList,
  faLink,
} from "@fortawesome/free-solid-svg-icons";

import $ from "jquery";

export default (props) => {
  const { cache, gridState, preview, icons } = useSelector((state) => state);
  const {
    id,
    zIndex,
    insertComponent,
    removeComponent,
    title,
    parent,
    renderFrontLiner,
    renderBackLiner,
    PropsSwitching,
    postfix,
    style,
    prefix,
    stylePrefix,
    stylePostfix,
    icon,
  } = props;

  const [drop, setDrop] = useState(false);

  const dispatch = useDispatch();

  const isActive = () => {
    const { activeComponent, hoverComponent } = cache;
    if (activeComponent.indexOf(id) !== -1 || hoverComponent == id) {
      return true;
    }
    return false;
  };

  const SwitchingState = () => {
    const { activeComponent } = cache;
    if (activeComponent != id) {
      dispatch({
        branch: "design-ui",
        type: "setActiveComponent",
        payload: {
          id,
        },
      });
      $("#property-trigger").click();
    }
  };

  const ComponentHover = () => {
    dispatch({
      branch: "design-ui",
      type: "setHoverComponent",
      payload: {
        id,
      },
    });
  };

  const isAChildOfAFlex = () => {
    if (parent) {
      const { name } = parent;
      if (name == "flex") {
        return true;
      }
    }
    return false;
  };

  const isAChildOfForm = () => {
    if (parent) {
      const { name } = parent;
      if (name == "table") {
        return true;
      }
    }
    return false;
  };

  const isAChildOfAny = () => {
    if (parent) {
      return true;
    }
    return false;
  };

  if (preview) {
    return (
      <div className="table-icon" style={{ ...style }}>
        <FontAwesomeIcon icon={icons[icon]?.icon} />
      </div>
    );
  } else {
    return (
      <div className="design-zone-container" style={{ zIndex }}>
        {renderFrontLiner(id, parent)}
        <div
          className={`design-zone table-design chart-design ${
            isActive() ? "design-zone-active" : ""
          } `}
          onClick={SwitchingState}
          onMouseEnter={ComponentHover}
          style={{ zIndex }}
        >
          <span className="chart-title">{title}</span>
          <div
            className="p-2 d-flex"
            style={{ justifyContent: style?.justifyContent }}
          >
            {prefix && <span style={stylePrefix}>{prefix}</span>}
            <div className="button_link" style={{ ...style }}>
              <FontAwesomeIcon icon={icons[icon]?.icon} style={{color: style?.color, fontSize: style?.fontSize}}/>
            </div>
            {postfix && <span style={stylePostfix}>{postfix}</span>}
          </div>
        </div>
        {renderBackLiner(id, parent)}
      </div>
    );
  }
};
